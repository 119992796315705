import React, { useContext, useRef, useState } from 'react';
import FocusTrap from 'focus-trap-react';
import ResolveLink from '../../controls/resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import './QuizResults.scss';
import { generateResultsQueryString } from '../../utils/personalisationUtils';
import { PageName } from '../../models/PageName';
import { ComButton } from '@exporter-services/common-ui';

interface QuizResultsProps {
    industry: string;
    questions: Array<any>;
    generic_yes_message: any;
    generic_no_message: any;
}

const QuizResults = (props: QuizResultsProps) => {
    const refSidebar = useRef<HTMLDivElement | null>(null);
    const refInput = useRef<HTMLInputElement | null>(null);
    const [showPopup, setShowPopup] = useState(false);
    const [copied, setCopied] = useState(false);
    const { personalisationState } = useContext(personalisationContext);

    const nextStepsResults = () => {
        if (props.questions) {
            if (props.questions.every((val: any) => val.answer === 'yes')) {
                let returnedYesText = <ResolveLink data={{ item: props.generic_yes_message }} type={'quizItem'} />;
                return returnedYesText.props.data.item;
            } else {
                let returnedNoText = <ResolveLink data={{ item: props.generic_no_message }} type={'quizItem'} />;
                return returnedNoText.props.data.item;
            }
        }
    };

    const nextStepsMoreResults = () => {
        if (props.questions) {
            let results: any[] = [];
            for (let question of props.questions) {
                if (question.answer === 'no' || question.answer === 'notsure') {
                    let returnedResults = <ResolveLink data={{ item: question }} type={'quizItem'} />;
                    results.push(returnedResults.props.data.item);
                }
            }

            return results;
        }
    };

    const saveProgress = () => {
        localStorage.setItem('quiz - ' + props.industry, JSON.stringify(props.questions));
        setCopied(false);
        toggle();
    };

    function toggle() {
        setShowPopup(!showPopup);
    }

    const copyLinkToClipboard = (e: any) => {
        if (refInput.current) {
            let el = refInput.current;
            el.select();
            document.execCommand('copy');
        }
        setCopied(true);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.preventDefault();
        let target = event.currentTarget;
        setTimeout(() => target.select(), 0);
    };

    const generateResultsUrl = () => {
        return `${window.location.origin}/${PageName.GetStartedWithExport}/${PageName.AreYouReadyToExport}${generateResultsQueryString(personalisationState, props.questions)}`;
    };

    const Modal = () => (
        <React.Fragment>
            <FocusTrap>
                <div>
                    <div className="modal-overlay" />
                    <div className="modal-wrapper" aria-modal={true} aria-hidden={true} tabIndex={-1} role="dialog">
                        <div className="modal">
                            <h4 className="success">You have saved your responses</h4>
                            <form>
                                <label>Use this link to continue on from where you were:</label>
                                <div className="flex-wrapper">
                                    <div className="progress-link-wrapper">
                                        <input
                                            type="text"
                                            id="progress-link"
                                            name="progress-link"
                                            value={generateResultsUrl()}
                                            ref={refInput}
                                            readOnly
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleFocus(e)}
                                            data-testid="progress-link"
                                        />
                                        <div className="input-fade-effect"></div>
                                    </div>
                                    {copied ? (
                                        <div className="copied-link-button" onClick={(e) => copyLinkToClipboard(e)}>
                                            <span>Link copied</span>
                                        </div>
                                    ) : (
                                        <div className="copy-link-button" onClick={(e) => copyLinkToClipboard(e)}>
                                            <span>Copy my link</span>
                                        </div>
                                    )}
                                </div>
                            </form>
                            <div className="quiz-results-close close-icon" onClick={toggle}></div>
                        </div>
                    </div>
                </div>
            </FocusTrap>
        </React.Fragment>
    );

    return (
        <div className="row mt-3">
            <div className="col-lg-8 col-md-12">
                <h2>Next steps</h2>
                <div>
                    <ResolveLink data={{ item: nextStepsResults() }} type={'quizItem'} />
                    <div className="quiz-results-container">
                        <ul>
                            {nextStepsMoreResults() &&
                                nextStepsMoreResults().map((a: any) => (
                                    <li key={a.id}>
                                        <ResolveLink data={{ item: a.next_steps }} type={'quizItem'} />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12">
                <div className="side-bar-container my-2 p-4" ref={refSidebar}>
                    <div className="email-intro">
                        <h3>Save my responses</h3>
                        <p>Get a link to your responses so you can work through your next steps.</p>
                    </div>
                    <div id="email-btn-wrapper">
                        <ComButton variant="secondary" className="link-icon" onClick={() => saveProgress()} id="get_my_link">
                            <span>Get my link</span>
                        </ComButton>
                    </div>
                </div>
            </div>
            {showPopup && <Modal />}
        </div>
    );
};

export default QuizResults;
