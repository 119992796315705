export const CommonConstants = {
    KONTENT_TAXONOMY_NOT_LISTED_CODENAME: 'not_listed',
    KONTENT_TAXONOMY_NOT_LISTED_LABEL: 'Not listed',
    KONTENT_TAXONOMY_NOT_SURE_CODENAME: 'not_sure',
    KONTENT_TAXONOMY_NOT_SURE_LABEL: 'Not sure',
    KONTENT_TAXONOMY_ALL_CODENAME: 'all',
    KONTENT_HSCODES_TOOLTIP_CODENAME: 'hscodes_tooltip_text',
    KONTENT_HSCODES_ALL_CODENAME: 'n00',

    EXPORT_TYPE_SELECTION_LABEL: 'Select product or sector',
    PRODUCTS_RADIOBUTTON_VALUE: 'products',
    PRODUCTS_RADIOBUTTON_TITLE: 'Product',
    SERVICES_RADIOBUTTON_VALUE: 'services',
    SERVICES_RADIOBUTTON_TITLE: 'Sector',

    PRODUCTS_CODENAME: 'products',
    PRODUCTS_LABEL: 'Products',

    CLEAR_FILTERS_LABEL: 'Clear filters',
    PAGE_SIZE: 10,
    LOCALSTORAGE_USERID_KEY: 'userId',
};
