import React from 'react';
import { Col, Row } from 'reactstrap';
import { ContentItem } from '@exporter-services/common-ui';
import BaseImage from '../baseImage/BaseImage';
import './EventSpeaker.scss';

interface EventSpeakerProps {
    data: {
        item: ContentItem;
    };
}

export const EventSpeaker = (props: EventSpeakerProps) => {
    return (
        <div className="event-speaker-container">
            <Row>
                <Col className="speaker-image-container">
                    <BaseImage
                        src={props.data.item.elements.image?.value?.[0]?.url}
                        alt={props.data.item.elements.image?.value?.[0]?.description}
                        className="speaker-image"
                    ></BaseImage>
                </Col>
                <Col md={8} className="mt-4 mt-md-0 flex-fill">
                    <div className="speaker">
                        <strong>{props.data.item.elements.name?.value}</strong>
                        <p>{props.data.item.elements.position?.value}</p>
                    </div>
                    <div className="speaker-bio" dangerouslySetInnerHTML={{ __html: props.data.item.elements.bio?.value }}></div>
                </Col>
            </Row>
        </div>
    );
};
