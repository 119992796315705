import { fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { LocalStorageCache } from '@auth0/auth0-react';
import { WrappedCacheEntry } from '@auth0/auth0-spa-js';

export const getAccessToken = () => {
    const auth0LocalStorage = new LocalStorageCache();
    const authDataKey = auth0LocalStorage.allKeys().find((key) => key.includes('openid'));

    return new Promise((resolve) => resolve(auth0LocalStorage.get(authDataKey))).then((authData: WrappedCacheEntry) => authData?.body?.access_token);
};

export const baseQueryWithAuth = (baseUrl: string) =>
    fetchBaseQuery({
        baseUrl,
        credentials: 'include',
        prepareHeaders: (headers) => {
            return getAccessToken().then((token) => {
                if (!headers.has('Authorization') && token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
                return headers;
            });
        },
    });

export const handleUnauthorizedResponse = (error: FetchBaseQueryError) => {
    if (error?.status === 401) window.location.search = 'forcelogout=true';

    return error;
};
