export const redirectTo = (url: string) => {
    document.location.href = url;
    return false;
};

export const capitalise = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const strPadLeft = (string: string | number, pad: string, length: number) => (new Array(length + 1).join(pad) + string).slice(-length);

export const removeLastTwoChar = (code: string) => code.slice(0, -2);

export const replaceAll = (str, find, replace) => str?.replace(new RegExp(find, 'g'), replace);

export const stripSpecialCharacters = (value: string) => value.replaceAll(/[^a-z0-9, -]/gi, ' ').replaceAll(/^\s+|\s+$|\s+(?=\s)/g, '');

export const sanitiseCapturingGroup = (regexTerm: string, stripSpecialChars = false) => {
    let sanitisedText = regexTerm.replace(/[.*+?^#${}()|[\]\\]/g, '\\$&');

    return stripSpecialChars ? stripSpecialCharacters(sanitisedText) : sanitisedText;
};
