export enum PageName {
    PricingCostsAndFinance = 'pricing-costs-and-finance',
    FindTariffs = 'tariff-finder-tool',
    Tariffs = 'tariffs',
    GuideToExporting = 'guide-to-exporting',
    RulesAndRestrictions = 'rules-and-restrictions',
    LatestUpdates = 'latest-updates',
    NewsAndEvents = 'news-and-events',
    LawsAndRegulations = 'laws-and-regulations',
    ExportRulesFinder = 'export-rules-finder',
    ProductRequirements = 'product-requirements',
    ServiceRequirements = 'service-requirements',
    RulesAndRestrictionsDocument = 'product-documents',
    GetStartedWithExport = 'get-started-with-exporting',
    AreYouReadyToExport = 'are-you-ready-to-export',
    NewsFilterPage = 'news-and-updates',
    FindExportMarkets = 'find-export-markets',
    Shortlist = 'shortlist',
    ShortlistProducts = 'shortlist-products',
    MarketSearchTool = 'market-search-tool',
    EventsSearchTool = 'events-search-tool',
    MarketExplorerTool = 'market-explorer-tool',
    MarketExplorerResults = 'market-explorer-results',
    SiteSearchResults = 'search-results',
    SignIn = 'sign-in',
    MyAccount = 'my-account',
    CompleteYourProfile = 'complete-your-profile',
    Error = 'error',
}
