import React, { useEffect } from 'react';
import BodyCopy from '../bodyCopy/BodyCopy';
import { BodyCopyItem } from '../bodyCopy/BodyCopyItem';
import useCommodityIndustryMarketHelper from '../../hooks/useCommodityIndustryMarketHelper';
import { formatDate } from '../../utils/dateUtils';
import { ContentItem } from '@exporter-services/common-ui';

interface MarketChallengesProps {
    data: {
        item: ContentItem;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const MarketChallenges = (props: MarketChallengesProps) => {
    const helper = useCommodityIndustryMarketHelper(props);

    useEffect(() => {
        helper.getCommodityIndustryMarketTypes('challenges');
    }, []);

    return (
        <>
            {helper.content && helper.linkedItems && (
                <BodyCopy data={{ item: helper.content as BodyCopyItem, linkedItems: helper.linkedItems }} match={props.match} displayTitle={false} />
            )}
            {helper.content?.elements.last_updated_date__last_updated?.value && (
                <p>
                    <strong>Last updated:</strong> {formatDate(helper.content.elements.last_updated_date__last_updated.value)}
                </p>
            )}
        </>
    );
};

export default MarketChallenges;
