import { instantiateKenticoClient } from '@exporter-services/common-ui';

export const auth0Audience = window['env'].Auth0Audience as string;
export const auth0ClientId = window['env'].Auth0ClientId as string;
export const auth0Domain = window['env'].Auth0Domain as string;

export const isExpansionWorkEnabled = window['env'].FeatureManagementEnableExpansionWork as boolean;
export const isSiteSearchEnabled = window['env'].FeatureManagementEnableSiteSearch as boolean;

export const kenticoProjectId = window['env'].KenticoProjectId as string;
const kenticoPreviewApiKey = window['env'].KenticoPreviewApiKey as string;
const kenticoUsePreviewMode = window['env'].KenticoUsePreviewMode as boolean;

export const client = instantiateKenticoClient(kenticoProjectId, kenticoUsePreviewMode, kenticoPreviewApiKey);
