import React, { useEffect, useState } from 'react';
import { DeliveryClient } from '@kontent-ai/delivery-sdk';
import './EventTilePathway.scss';
import { EventTilePathwayItem } from './EventTilePathwayItem';
import { ComImage, getParentPath } from '@exporter-services/common-ui';
import { formatEventStartDateEndDate } from '../../utils/dateUtils';

export interface EventTilePathwayProps {
    data: {
        content: EventTilePathwayItem;
    };
    client: DeliveryClient;
}

const EventTilePathway = (props: EventTilePathwayProps) => {
    const [eventDate, setEventDate] = useState<string>(null);
    const [parentPath, setParentPath] = useState<string>(null);

    let item = props.data.content;

    useEffect(() => {
        let eventDateValue = getEventDateDetails(props.data.content);
        setEventDate(eventDateValue);
        getParentPath(props.client, props.data.content.system.codename, item.elements.url.value, setParentPath);
    }, []);

    const getEventDetails = (item: EventTilePathwayItem) => {
        let eventDetails = item.elements.content['linkedItems'].find((i) => i.system.type === 'event_details');
        if (!eventDetails) {
            eventDetails = item.elements.right_hand_content['linkedItems'].find((i) => i.system.type === 'event_details');
        }
        return eventDetails;
    };

    const getEventDateDetails = (item: EventTilePathwayItem) => {
        let result = '';
        let eventDetails = getEventDetails(item);

        if (eventDetails) {
            result = formatEventStartDateEndDate(eventDetails.elements.start_date?.value, eventDetails.elements.end_date?.value);
        }
        return result;
    };

    const getLabelDescription = (item: EventTilePathwayItem) => {
        return item.elements.label?.value?.length > 0
            ? item.elements.label.value[0].toUpperCase() + item.elements.label.value.slice(1).toLowerCase()
            : '';
    };

    const getTagClassPrefix = (item: EventTilePathwayItem) => {
        if (item.elements.label?.value?.toUpperCase() === 'WEBINAR') {
            return 'webinar';
        }
        return 'on-demand';
    };

    const getAbstractText = (item: EventTilePathwayItem) => {
        const wordLimit = 180;
        if (item.elements.abstract?.value?.length > 180) {
            let abstractText = item.elements.abstract?.value;
            let endOfSentence = abstractText.substring(wordLimit, abstractText.length);
            let charactersUntilNextWord = endOfSentence.indexOf(' ');
            let totalWordLimit = wordLimit + charactersUntilNextWord;
            return abstractText.substring(0, totalWordLimit) + (totalWordLimit < abstractText.length ? ' ...' : '');
        }
        return item.elements.abstract?.value;
    };

    return (
        <div className="event-tile-pathway">
            <a className="event-tile-pathway-anchor" href={parentPath}>
                <div className="event-tile-pathway-list-button event-tile-pathway-list-wrapper">
                    <div className="event-tile-pathway-list-flexItem">
                        {item.elements.image?.value?.length > 0 && (
                            <ComImage src={item.elements.image?.value[0]?.url} alt={item.elements.image?.value[0]?.description} />
                        )}
                    </div>
                    <div className="event-tile-pathway-list-body">
                        {item.elements.label?.value && (
                            <div className="event-tile-pathway-list-label-wrapper">
                                <div className={`tag ${getTagClassPrefix(item)}-tag event-tile-pathway-list-label-text`}>
                                    {getLabelDescription(item)}
                                </div>
                            </div>
                        )}
                        <div className="event-tile-pathway-list-title">
                            <h3>{item.elements.title.value}</h3>
                        </div>
                        {eventDate && (
                            <div className="event-tile-pathway-list-date-wrapper">
                                <div className="text" dangerouslySetInnerHTML={{ __html: eventDate }}></div>
                            </div>
                        )}
                        <div className="event-tile-pathway-list-description">{getAbstractText(item)}</div>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default EventTilePathway;
