import React from 'react';
import { FilterCheckbox, FilterItemInteraction } from '@exporter-services/common-ui';
import '../../styles/_filterPages.scss';
import { SiteSearchFilter } from '../../models/SiteSearch';

interface FilterProps {
    filterOptions: Array<SiteSearchFilter>;
    filterResults: (filter: Array<string>) => void;
}

export const SiteSearchResultsFilterPanel = ({ filterOptions, filterResults }: FilterProps) => {
    const prepareFilter = (item: FilterItemInteraction) => {
        const newFilter = filterOptions
            .map((filter) =>
                (filter.Category === item.text && item.selected) || (filter.Category !== item.text && filter.Selected) ? filter.Category : undefined,
            )
            .filter(Boolean);

        return filterResults(newFilter);
    };

    return (
        <div className="filter-main-panel">
            {filterOptions.map((filter: SiteSearchFilter) => {
                const identifier = filter.Category.toLowerCase().replaceAll(' ', '_');
                return (
                    <div key={identifier}>
                        <hr className="filter-main-panel-separator" />
                        <FilterCheckbox
                            data={{
                                id: identifier,
                                value: identifier,
                                label: filter.Category,
                                count: filter.Count,
                                checked: filter.Selected,
                                clearState: false,
                            }}
                            onChange={prepareFilter}
                        />
                    </div>
                );
            })}
            <hr className="filter-main-panel-separator" />
        </div>
    );
};
