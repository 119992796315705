import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import BypassBlock from './BypassBlock';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { redirectTo } from './utils/utils';
import * as ReactGA from 'react-ga';
import 'autotrack';
import { Provider } from 'react-redux';
import store from './providers/globalState/store';
import { auth0Audience, auth0ClientId, auth0Domain } from './config';
import { PageName } from './models/PageName';

// Here we have to use GA and GTM both which is unusual
// but because of the requirement of capturing the search term when a user type
// a word to search user story #30367

ReactGA.plugin.require('autotrack');

ReactGA.initialize('UA-2926237-20', {
    debug: false,
    titleCase: false,
});

//uncomment this for debug purpose
// ReactGA.initialize([{
//     trackingId: 'UA-2926237-20',
//     gaOptions: {
//         name: 'tracker1',
//     }
// }], { debug: true, alwaysSendToDefaultTracker: false });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

if (process.env.NODE_ENV !== 'development') {
    root.render(
        <React.StrictMode>
            <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                    audience: auth0Audience,
                    redirect_uri: `${window.location.origin}/callback?url=${window.location.pathname}`,
                }}
                useRefreshTokens
                cacheLocation="localstorage"
            >
                <Provider store={store}>
                    <BypassBlock />
                    <App />
                </Provider>
            </Auth0Provider>
        </React.StrictMode>,
    );
} else {
    root.render(
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            authorizationParams={{
                audience: auth0Audience,
                redirect_uri: `${window.location.origin}/callback?url=${window.location.pathname}`,
            }}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            <Provider store={store}>
                <BypassBlock />
                <App />
            </Provider>
        </Auth0Provider>,
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener('error', (e) => {
    // Get the error properties from the error event object
    const { message, filename, lineno, colno, error } = e;

    console.error(message);
    console.warn(filename + ':' + lineno + ':' + colno);

    if (error?.stack) {
        console.warn(error.stack);
    }

    if (process.env.NODE_ENV !== 'development') {
        redirectTo(`/${PageName.Error}`);
    }

    return true;
});
