import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SiteSearchResponse } from '../../models/SiteSearch';

export const siteSearchApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api/sitesearch' }),
    reducerPath: 'siteSearchApi',
    endpoints: (builder) => ({
        getSiteSearchResults: builder.query<SiteSearchResponse, { searchTerm: string; pageNumber: number; pageSize: number; filter: string }>({
            query: ({ searchTerm, pageNumber, pageSize, filter }) =>
                `?searchTerm=${searchTerm}&pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}`,
            transformResponse: (response: SiteSearchResponse) => response,
        }),
        getAutoCompleteResults: builder.query<Array<string>, { searchTerm: string }>({
            query: ({ searchTerm }) => `autocomplete?searchTerm=${searchTerm}`,
            transformResponse: (response: Array<string>) => response,
        }),
    }),
});

export const { useGetSiteSearchResultsQuery, useLazyGetAutoCompleteResultsQuery } = siteSearchApi;

export default siteSearchApi.reducer;
