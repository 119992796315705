import GlobalState, { UserState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserGeolocationInfo } from '../../models/ReduxModels';

const initState: UserState = {
    geolocationInfo: {
        retrieved: false,
        pending: false,
        data: undefined,
    },
};

export const getUserGeolocation = createAsyncThunk('user/getUserGeolocation', async (request: {}, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;

    if (state.user.geolocationInfo.retrieved || state.user.geolocationInfo.pending) {
        return thunkAPI.rejectWithValue(true);
    }
    thunkAPI.dispatch(userSlice.actions.pendingCurrentMarketNameInfo());

    return await new Promise<UserGeolocationInfo>((resolve) => {
        (thunkAPI.getState() as GlobalState).singletonServices.userService
            .getGeolocation()
            .then((resp) => {
                const isOffshore = (thunkAPI.getState() as GlobalState).singletonServices.userService.isOffshore(resp);
                resolve({ retrieved: true, pending: false, data: { code: resp, isOffshore: isOffshore } });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false, data: undefined });
            });
    });
});

const userSlice = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        pendingCurrentMarketNameInfo(state) {
            state.geolocationInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserGeolocation.fulfilled, (state, action) => {
            state.geolocationInfo = action.payload as UserGeolocationInfo;
        });
    },
});

const userReducer = userSlice.reducer;

export default userReducer;
