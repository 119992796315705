import React, { BaseSyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { omit } from 'lodash';
import { IContentItem, IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ComFieldInput, ComFieldSelect } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { KenticoUserTypes, UserType } from '../../models/ReduxModels';
import { getKenticoUserTypes } from '../../providers/reducers/taxonomySlice';
import ComponentResolver from '../../ComponentResolver';
import { scrollTo } from '../../utils/scrollUtils';
import { AbnField } from '../abn/AbnField';
import { useCreateProfileMutation } from '../../providers/reducers/userProfileApi';
import { PageName } from '../../models/PageName';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { CommonConstants } from '../../CommonConstants';

interface UserProfileProps {
    data: {
        item: IContentItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

export const UserProfile = (props: UserProfileProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const { isMobile, isTablet } = useGetDisplayType();
    const dispatch = useAppDispatch();
    const userTypeTaxonomy = useAppSelector<KenticoUserTypes>((state) => state.taxonomy.userTypes);
    const navigate = useNavigate();
    const [createProfile] = useCreateProfileMutation();

    const [selectedUserType, setUserType] = useState('');
    const [displayLoader, setDisplayLoader] = useState(false);

    const formContext = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' });
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        getValues,
        reset,
    } = formContext;

    const displayAdditionalFields = useMemo(() => selectedUserType.endsWith('_business'), [selectedUserType]);

    const handleUserTypeFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(formRef);
        }
    };

    const saveUserProfile = async (data: FieldValues) => {
        if (displayLoader) return;

        const apiPayload = !data.userType.endsWith('_business')
            ? omit(data, ['abn', 'role'])
            : { ...data, role: data.role.trim(), abn: data.abn.replaceAll(' ', '') };

        const response = await createProfile({
            Abn: apiPayload.abn,
            BusinessType: apiPayload.userType,
            Role: apiPayload.role,
        });

        if (response.error) {
            navigate(`/${PageName.Error}`, { replace: false });
        } else {
            let userId = response.data?.Id;
            localStorage.setItem(CommonConstants.LOCALSTORAGE_USERID_KEY, userId);
            TagManager.dataLayer({
                dataLayer: {
                    event: 'account_created',
                    dlv_user_type: apiPayload.userType,
                    dlv_abn_provided: apiPayload.abn ? true : false,
                    dlv_user_role: apiPayload.role,
                },
            });
            navigate(`/${PageName.MyAccount}`, { replace: true });
        }
    };

    useEffect(() => {
        dispatch(getKenticoUserTypes());
    }, []);

    return (
        <LoadingOverlay active={displayLoader} spinner={<SyncLoader />} text="Fetching ABN details. Please wait..." className="loader">
            <FormProvider {...formContext}>
                <form
                    className={`mb-5${displayLoader ? ' overflow-hidden' : ''}`}
                    onSubmit={handleSubmit((data) => saveUserProfile(data))}
                    ref={formRef}
                >
                    <ComFieldSelect
                        controlId="userType"
                        label="Choose the option that best describes you"
                        errorMessage={errors?.userType?.message as string}
                        required
                        options={userTypeTaxonomy?.data?.map((userType: UserType) => ({ key: userType.codename, value: userType.name }))}
                        displayEmptyOption
                        emptyOptionText="Select the best option..."
                        onFocus={handleUserTypeFocus}
                        selectedValue={getValues('userType')}
                        {...register('userType', {
                            required: 'Please select an option',
                            onChange: (event: BaseSyntheticEvent) => {
                                setUserType(event.target?.value);
                                setValue('userType', event.target?.value, { shouldValidate: true });
                            },
                        })}
                    />
                    {displayAdditionalFields && (
                        <>
                            <AbnField isFetchingAbnDetails={setDisplayLoader} />
                            <ComFieldInput
                                controlId="role"
                                label="Your role in the organisation"
                                hintText="Example, Export Manager or CEO."
                                errorMessage={errors?.role?.message as string}
                                required
                                {...register('role', {
                                    maxLength: { value: 100, message: "Check your role doesn't have more than 100 characters." },
                                    validate: {
                                        required: (value) => {
                                            if (value.trim().length < 2) return 'Enter a minimum of 2 characters.';
                                        },
                                    },
                                })}
                            />
                        </>
                    )}
                    {props.data.item?.elements?.linked_items?.['linkedItems']?.map((item: IContentItem) => (
                        <ComponentResolver data={{ item: item, linkedItems: props.data.linkedItems }} key={item.system.id} match={props.match} />
                    ))}
                    <div className="button-container">
                        <ComButton
                            variant="link"
                            onClick={() => {
                                reset({ userType: '', abn: '', role: '' });
                                setUserType('');
                            }}
                            disabled={isSubmitting}
                        >
                            Clear form
                        </ComButton>
                        <ComButton type="submit" disabled={isSubmitting}>
                            Save
                        </ComButton>
                    </div>
                </form>
            </FormProvider>
        </LoadingOverlay>
    );
};
