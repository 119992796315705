import React, { FocusEventHandler, HTMLAttributes } from 'react';
import { ComFieldWrapper } from './ComFieldWrapper';

interface ComFieldSelectOption {
    key: string | number;
    value: string;
}

interface ComFieldSelectProps {
    controlId: string;
    label: string;
    hintText?: string;
    errorMessage?: string;
    required?: boolean;
    options?: Array<ComFieldSelectOption>;
    displayEmptyOption?: boolean;
    emptyOptionText?: string;
    onFocus?: FocusEventHandler;
    selectedValue?: string;
}

export const ComFieldSelect = React.forwardRef<HTMLDivElement, ComFieldSelectProps & HTMLAttributes<{}>>(
    (props: ComFieldSelectProps & HTMLAttributes<{}>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const {
            controlId,
            label,
            hintText,
            errorMessage,
            required,
            options,
            displayEmptyOption,
            emptyOptionText = 'Please select...',
            onFocus,
            selectedValue,
            ...rest
        } = props;
        const labelId = `${controlId}-label`;
        const hintTextId = `${controlId}-hint`;
        const errorId = `${controlId}-error`;

        return (
            <ComFieldWrapper
                labelId={labelId}
                label={label}
                hintTextId={hintTextId}
                hintText={hintText}
                errorId={errorId}
                errorMessage={errorMessage}
                required={required}
                ref={ref}
            >
                <select
                    {...rest}
                    aria-labelledby={hintText ? `${labelId} ${hintTextId}` : labelId}
                    aria-invalid={!!errorMessage}
                    aria-errormessage={errorMessage}
                    aria-describedby={errorMessage ? errorId : ''}
                    onFocus={onFocus}
                    value={selectedValue}
                >
                    {displayEmptyOption && (
                        <option key="please_select" value="">
                            {emptyOptionText}
                        </option>
                    )}
                    {options?.map((option: ComFieldSelectOption) => (
                        <option key={option.key} value={option.key}>
                            {option.value}
                        </option>
                    ))}
                </select>
            </ComFieldWrapper>
        );
    },
);
