import React, { useContext, useEffect, useRef, useState } from 'react';
import './Video.scss';
import YouTubePlayer from 'react-player/youtube';
import ResolveLink from '../resolveLink/ResolveLink';
import { personalisationContext } from '../../providers/personalisation';
import { ContentItem } from '@exporter-services/common-ui';
import { validatePersonalisation } from '../../utils/personalisationUtils';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';

interface VideoProps {
    data: {
        item: ContentItem;
    };
}

const Video = (props: VideoProps) => {
    const [videoUrl, setvideoUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [seeking] = useState<boolean>(false);
    const [, setPlayedSeconds] = useState(null);
    const refVideo = useRef<YouTubePlayer | null>(null);
    const { personalisationState } = useContext(personalisationContext);

    useEffect(() => {
        updateVideoData();
    }, [props.data.item.elements.video_id.value]);

    const updateVideoData = () => {
        const url: string = `https://www.youtube.com/watch?v=${props.data.item.elements.video_id.value}`;
        const defaultImgUrl: string = `https://img.youtube.com/vi/${props.data.item.elements.video_id.value}/0.jpg`;

        setvideoUrl(url);
        setImageUrl(defaultImgUrl);
    };

    const onPlay = (): void => {
        setPlaying(true);
    };

    const onEnded = (): void => {
        setPlaying(false);
    };

    const onProgress = (state): void => {
        if (!seeking) {
            setPlayedSeconds(state.playedSeconds);
        }
    };

    const clickHandler = () => {
        setPlaying(true);
    };

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <div className="video-player" data-testid="video-player">
                    <div className="video-player-header">
                        <HeadingLevelResolver
                            data={{
                                headingLevel: props.data.item.elements.headinglevelsnippet__level,
                                titleText: props.data.item.elements.title.value,
                            }}
                        />
                        <ResolveLink data={{ item: props.data.item }} type={props.data.item.system.type} />
                    </div>
                    <div
                        className="video-player-player"
                        onClick={clickHandler}
                        style={{
                            backgroundImage: imageUrl
                                ? `url(${imageUrl})`
                                : 'https://img.youtube.com/vi/' + props.data.item.elements.video_id.value + '/maxresdefault.jpg',
                        }}
                    >
                        <button
                            aria-label={`Play video ${props.data.item.elements.title.value}`}
                            className="video-player-button"
                            onClick={clickHandler}
                            style={{
                                visibility: !playing ? 'visible' : 'hidden',
                            }}
                        ></button>
                        <YouTubePlayer
                            className="video-player-player-youtube"
                            style={{
                                visibility: playing ? 'visible' : 'hidden',
                            }}
                            config={{
                                playerVars: {
                                    color: 'white',
                                    showinfo: 0,
                                    modestbranding: 1,
                                    controls: 1,
                                    enablejsapi: 1,
                                    rel: 0,
                                },
                            }}
                            width="100%"
                            height="100%"
                            playing={playing}
                            ref={refVideo}
                            url={videoUrl}
                            onSeek={() => null}
                            controls
                            onPlay={onPlay}
                            onEnded={onEnded}
                            onProgress={onProgress}
                        />
                    </div>

                    <div className="video-player-transcript">
                        <details>
                            <summary>
                                <div className="transcript-toggle"></div>
                                <div className="arrow-down-icon"></div>
                            </summary>
                            <div dangerouslySetInnerHTML={{ __html: props.data.item.elements.video_transcript?.value }}></div>
                        </details>
                    </div>
                </div>
            )}
        </>
    );
};

export default Video;
