import React, { useEffect, useState } from 'react';
import './EventResultsContainer.scss';
import { client } from '../../config';
import { EventTilePathwayItem } from '../eventTilePathway/EventTilePathwayItem';
import EventTilePathway from '../eventTilePathway/EventTilePathway';
import { Col, Row } from 'reactstrap';
import { ContentItem } from '@exporter-services/common-ui';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';

interface EventResultsContainerProps {
    data: {
        item: ContentItem;
    };
}

const EventResultsContainer = (props: EventResultsContainerProps) => {
    const [eventPageContentItems, setEventPageContentItems] = useState<EventTilePathwayItem[]>(null);

    useEffect(() => {
        getEventPageContentItems();
    }, []);

    const getEventPageContentItems = () => {
        if (!(props.data.item?.elements?.event_type?.value?.length > 0)) {
            return;
        }

        let eventType = props.data.item?.elements?.event_type?.value[0].codename;

        //get contents by using event type as filter
        if (props)
            client
                .items()
                .equalsFilter('system.type', 'event_page')
                .containsFilter('elements.event_type', [eventType])
                .depthParameter(2)
                .toPromise()
                .then((response) => {
                    setEventPageContentItems(response.data.items);
                });
    };

    return (
        <>
            {eventPageContentItems?.length > 0 && (
                <div className="event-results-container">
                    <HeadingLevelResolver
                        data={{
                            headingLevel: props.data.item.elements.headinglevelsnippet__level,
                            titleText: props.data.item.elements.title?.value,
                        }}
                    />
                    <Row className="event-results-container-items full-width">
                        {eventPageContentItems.map((item: EventTilePathwayItem) => (
                            <Col lg={4} sm={6} className="list-block" key={item.system.id}>
                                <EventTilePathway data={{ content: item }} client={client} key={item.system.id} />
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </>
    );
};

export default EventResultsContainer;
