import React from 'react';
import { formatDate } from '../../utils/dateUtils';
import './EventPathway.scss';
import { EventPathwayItem } from './EventPathwayItem';
import parse from 'html-react-parser';
import { getLinkOpenTypeWithDefaultReturnString } from '../../utils/linkOpenTypeHelper';

interface EventPathwayProps {
    data: {
        items: EventPathwayItem[];
    };
}

const EventPathway = (props: EventPathwayProps) => {
    const extractEventDate = (item: EventPathwayItem) => {
        const sDate = item.elements.event_start_date.value;
        const eDate = item.elements.event_end_date.value;

        if (!sDate) return 'Check website';
        const startDate = formatDate(new Date(sDate));
        const endDate = eDate ? ` to ${formatDate(new Date(eDate))}` : '';

        return `${startDate}${endDate}`;
    };

    return (
        <>
            {props.data.items.map((item: EventPathwayItem, i) => (
                <div className="event-pathway-wrapper" key={i}>
                    {item.elements.link && (
                        <a className="event-pathway-anchor" href={item.elements.link.value} target={getLinkOpenTypeWithDefaultReturnString(item)}>
                            <div className="event-pathway-card">
                                <div className="event-pathway-card-content">
                                    {item.elements.label?.value && (
                                        <div className="label-search-cards event-pathway-card-category">{item.elements.label.value}</div>
                                    )}
                                    <h3 className="event-pathway-card-title">{item.elements.title.value}</h3>
                                    <div className="event-pathway-card-description">{parse(item.elements.description.value)}</div>
                                </div>
                                <div className="event-pathway-footer">
                                    <div className="tag location-tag">
                                        <div className="pin-icon"></div>
                                        <div>{item.elements.event_location.value}</div>
                                    </div>
                                    <div className="tag date-tag">
                                        <div className="calendar-icon-orange"></div>
                                        <div>{extractEventDate(item)}</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                </div>
            ))}
        </>
    );
};

export default EventPathway;
