import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { getParentPage } from '@exporter-services/common-ui';
import { client } from '../config';
import { useAppSelector } from '../hooks/reduxHooks';
import { capitalise } from '../utils/utils';

interface HeadProps {
    metaDescription: string;
    abstract: string;
    pageTitle: string;
    titleTag: string;
    industry?: string;
    sector?: string;
    sectorLabel?: string;
    market?: string;
}

const EbgaHead = (props: HeadProps) => {
    const [parentPageName, setParentPageName] = useState<string>();
    const [parentPageRetrieved, setParentPageRetrieved] = useState<boolean>();
    const pageInfo = useAppSelector((state) => state.page.pageInfo);

    useEffect(() => {
        if (!pageInfo) {
            return;
        }

        getParentPage(client, pageInfo.codename).then((response) => {
            if (response) {
                setParentPageName(
                    response.elements.common_page_info__title_tag.value
                        ? response.elements.common_page_info__title_tag.value
                        : response.elements.title.value,
                );
            }

            setParentPageRetrieved(true);
        });
    }, [pageInfo?.codename]);

    const getPageTitle = () => {
        let formattedTitleTag = props.titleTag;

        if (props.titleTag?.indexOf('sector')) {
            formattedTitleTag = formattedTitleTag.replace(/{{sector}}/g, props.sector);
        }
        if (props.titleTag?.indexOf('ahecc')) {
            formattedTitleTag = formattedTitleTag.replace(/{{ahecc}}/g, props.sector);
        }
        if (props.titleTag?.indexOf('market')) {
            formattedTitleTag = formattedTitleTag.replace(/{{market}}/g, props.market);
        }

        return `${props.titleTag ? formattedTitleTag : props.pageTitle}${parentPageName ? ` | ${parentPageName}` : ''}`;
    };

    const getMetaDescription = () => {
        let formattedMetaDescription = props.metaDescription;

        if (formattedMetaDescription) {
            let descriptionText = formattedMetaDescription.replace(/{{market}}/g, props.market);
            if (props.sectorLabel) {
                const sector = capitalise(props.sectorLabel);
                descriptionText = descriptionText.replace(/{{sector}}/g, sector);
            }
            if (formattedMetaDescription.indexOf('ahecc')) {
                descriptionText = descriptionText.replace(/{{ahecc}}/g, props.sector);
            }
            formattedMetaDescription = descriptionText;
        }

        return (formattedMetaDescription ? formattedMetaDescription : props.abstract).replace(/<\/?[^>]+(>|$)/g, '');
    };

    return (
        parentPageRetrieved && (
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content={getMetaDescription()} data-react-helmet="true" />
                <title>{getPageTitle()}</title>
            </Helmet>
        )
    );
};

export default EbgaHead;
