import React, { useEffect, useState } from 'react';
import './PersonalisedPageSearchPanel.scss';
import { ComButton } from '@exporter-services/common-ui';
import { IndustryOption, SectorOption } from '../../models/IndustrySector';

interface PersonalisedPageSearchPanelProps {
    data: {
        industries: IndustryOption[];
        selectedIndustry: IndustryOption;
        selectedSector: SectorOption;
    };
    onClearFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onApplyFilter: (event: React.MouseEvent<HTMLButtonElement>, selectedIndustry: IndustryOption, selectedSector: SectorOption) => void;
}

const PersonalisedPageSearchPanel = (props: PersonalisedPageSearchPanelProps) => {
    const [tempSelectedIndustry, setTempSelectedIndustry] = useState<IndustryOption>(null);
    const [tempSelectedSector, setTempSelectedSector] = useState<SectorOption>(null);
    const [showSectorList, setShowSectorsList] = useState<boolean>(false);
    const [showApplyFilterButton, setShowApplyFilterButton] = useState<boolean>(false);

    useEffect(() => {
        setTempSelectedIndustry(props.data.selectedIndustry);
        setTempSelectedSector(props.data.selectedSector);

        if (props.data.selectedIndustry?.sectors.length > 0) {
            setShowSectorsList(true);
        } else {
            setShowSectorsList(false);
        }
    }, [props.data.selectedIndustry, props.data.selectedSector]);

    const handleOnSectorChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        let codeName = event.target.value;
        if (!codeName) return;
        let sector = tempSelectedIndustry.sectors.find((s) => s.codeName === codeName);
        setTempSelectedSector(sector);
        setShowApplyFilterButton(true);
    };

    const handleOnIndustryChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        let codeName = event.target.value;
        if (!codeName) return;
        let industry = props.data.industries.find((i) => i.codeName === codeName);

        setTempSelectedIndustry(industry);
        setTempSelectedSector(null);
        if (industry.sectors.length > 0) {
            setShowSectorsList(true);
            setShowApplyFilterButton(false);
        } else {
            setShowSectorsList(false);
            setShowApplyFilterButton(true);
        }
    };

    const handleOnApplyFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onApplyFilter?.(event, tempSelectedIndustry, tempSelectedSector);
    };

    const handleOnClearFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setTempSelectedIndustry(null);
        setTempSelectedSector(null);
        setShowSectorsList(false);
        setShowApplyFilterButton(false);
        props.onClearFilter?.(event);
    };

    const isIndustrySelected = (industry) => {
        return props.data.selectedIndustry && industry.codeName === props.data.selectedIndustry.codeName;
    };

    const isSectorSelected = (sector) => {
        return props.data.selectedSector && sector.codeName === props.data.selectedSector.codeName;
    };

    return (
        <div className="personalised-search-panel-container">
            <div className="filtergroup-form-field-container">
                <label className="field-label" htmlFor="industry">
                    Select industry
                </label>
                <select
                    value={
                        tempSelectedIndustry ? tempSelectedIndustry.codeName : props.data.selectedIndustry ? props.data.selectedIndustry.codeName : ''
                    }
                    id="industry"
                    data-testid="industry"
                    name="industry"
                    onChange={handleOnIndustryChanged}
                >
                    {!props.data.selectedIndustry && (
                        <option key="select_an_industry" value="">
                            Select an industry...
                        </option>
                    )}
                    {props.data.industries.map((industry) => (
                        <option
                            className={`${isIndustrySelected(industry) ? 'selected' : ''} filter-item-select-option`}
                            key={industry.codeName}
                            value={industry.codeName}
                        >
                            {industry.title}
                        </option>
                    ))}
                </select>
            </div>

            {showSectorList && (
                <div className="filtergroup-form-field-container">
                    <label className="field-label" htmlFor="sector">
                        Select sector
                    </label>
                    <select
                        value={tempSelectedSector ? tempSelectedSector.codeName : props.data.selectedSector ? props.data.selectedSector.codeName : ''}
                        id="sector"
                        data-testid="sector"
                        name="sector"
                        onChange={handleOnSectorChange}
                    >
                        {!tempSelectedSector && (
                            <option key="select_a_sector" value="">
                                Select a sector...
                            </option>
                        )}
                        {tempSelectedIndustry.sectors.map((sector) => (
                            <option className={`${isSectorSelected(sector) ? 'selected' : ''}`} key={sector.codeName} value={sector.codeName}>
                                {sector.title}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <div className="personalisation-filter-footer button-container">
                <ComButton id="clearFilters" variant="link" className="refresh-icon" aria-pressed="false" onClick={handleOnClearFilter}>
                    <span>Start again</span>
                </ComButton>
                {showApplyFilterButton && (
                    <ComButton id="personalization_filter_selection" onClick={handleOnApplyFilter}>
                        Apply
                    </ComButton>
                )}
            </div>
        </div>
    );
};

export default PersonalisedPageSearchPanel;
