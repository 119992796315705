import React from 'react';
import './Example.scss';
import { ExampleItem } from './ExampleItem';
import ResolveLink from '../resolveLink/ResolveLink';
import { filterContentItemAgainstUrlParams } from '../../utils/urlFilterUtils';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';
import { useUrlParams } from '../../hooks/useUrlParams';

interface ExampleProps {
    data: {
        item: ExampleItem;
    };
    match?: any;
}

const Example = (props: ExampleProps) => {
    const urlCodes = useUrlParams(props.match);
    const exampleItem = filterContentItemAgainstUrlParams(props.data.item, urlCodes);

    if (!exampleItem) return null;

    return (
        <div className="example-wrapper pb-5">
            <div className="example feature-box m-0">
                <div className="example-content py-4 ps-4 pe-8  h-100">
                    <div className="icon-wrapper me-3 mb-3">
                        <span className="case-study-icon"></span>
                    </div>
                    <div className="content-text no-space-under-last-child">
                        <HeadingLevelResolver
                            data={{
                                headingLevel: exampleItem.elements.headinglevelsnippet__level,
                                titleText: exampleItem.elements?.title?.value ?? 'Example',
                            }}
                        />
                        <ResolveLink data={{ item: exampleItem }} type={exampleItem.system.type} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Example;
