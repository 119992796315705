import { ArticleType, ArticleTypeLabels, NewsFilterItem } from '../../models/NewsFilterItem';
import { StringFilterDictionary } from '../toolsandtemplates/StringFilterDictionary';
import { descCountDictionary } from '../../utils/filterPanelUtils';
import _ from 'lodash';
import { FilterItem } from '@exporter-services/common-ui';

export type stringFilterDictionary = { [id: string]: boolean };

export class newsFilterUtils {
    static getArticleTypeList = (articleTypeList: NewsFilterItem[]) => {
        let tempArticleTypeList: descCountDictionary = {};
        if (articleTypeList?.length > 0) {
            const groupedArticleTypes = _.groupBy(articleTypeList, (type) => type.articleType);
            _.forEach(groupedArticleTypes, (g) => {
                tempArticleTypeList[ArticleType[g[0].articleType]] = {
                    description: ArticleTypeLabels[g[0].articleType],
                    count: g.length,
                };
            });

            return tempArticleTypeList;
        }
    };
    static getArticleTypeFilterList = (articleTypeCountDict: descCountDictionary) => {
        let tempFilterItemList: FilterItem[] = [];
        for (let key in articleTypeCountDict) {
            tempFilterItemList.push({ value: key, label: articleTypeCountDict[key].description, count: articleTypeCountDict[key].count });
        }

        return tempFilterItemList;
    };

    static getUpdatedArticleTypeListCount = (contentItemList: NewsFilterItem[], fullArticleTypeList: descCountDictionary): descCountDictionary => {
        let tempArticleTypeList = this.getArticleTypeList(contentItemList);

        // map filtered list count to full list count
        for (let key in fullArticleTypeList) {
            fullArticleTypeList[key].count = tempArticleTypeList?.[key]?.count || 0;
        }

        return { ...fullArticleTypeList };
    };

    static applyArticleTypesFilter = (filterCriteria: StringFilterDictionary, newFilterItems: NewsFilterItem[]) => {
        if (filterCriteria) {
            const filteredTypeCodes = Object.keys(filterCriteria).filter((typeCode) => filterCriteria[typeCode]);

            if (filteredTypeCodes.length > 0) {
                const filteredItems = newFilterItems.filter((item) => filteredTypeCodes.includes(ArticleType[item.articleType]));
                return filteredItems;
            }
        }
        return newFilterItems;
    };

    //#region
    static applySortCriteria = (sortCriteria: string, paramArticleList: NewsFilterItem[]) => {
        if (sortCriteria === 'Newest') {
            return newsFilterUtils.sortByDateTimeDesc(paramArticleList);
        } else {
            return newsFilterUtils.sortByDateTimeAsc(paramArticleList);
        }
    };

    static sortByDateTimeAsc = (paramArticleItems: NewsFilterItem[]) => {
        if (!paramArticleItems) return null;
        return paramArticleItems.sort((a, b) => a.lastUpdated.getTime() - b.lastUpdated.getTime());
    };

    static sortByDateTimeDesc = (articleItems: NewsFilterItem[]) => {
        if (!articleItems) return null;
        return articleItems.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
    };

    //#endregion

    //#region
    static getArticleCategory = (articleType: ArticleType) => {
        switch (articleType) {
            case ArticleType.BlogArticle:
                return 'Blog';
            case ArticleType.NewsArticle:
                return 'News';
            case ArticleType.SuccessStoryArticle:
                return 'Success story';
            default:
                return '';
        }
    };

    static getArticleIconClass = (articleType: ArticleType) => {
        switch (articleType) {
            case ArticleType.BlogArticle:
                return 'blog-icon';
            case ArticleType.NewsArticle:
                return 'news-icon';
            case ArticleType.SuccessStoryArticle:
                return 'success_story-icon';
            default:
                return '';
        }
    };

    static getArticleTagClass = (articleType: ArticleType) => {
        switch (articleType) {
            case ArticleType.BlogArticle:
                return 'blog-tag';
            case ArticleType.NewsArticle:
                return 'news-tag';
            case ArticleType.SuccessStoryArticle:
                return 'success_story-tag';
            default:
                return '';
        }
    };

    //#endregion
}
