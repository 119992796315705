import React, { FocusEventHandler, KeyboardEventHandler, HTMLAttributes } from 'react';
import { ComFieldWrapper } from './ComFieldWrapper';

interface ComFieldInputProps {
    controlId: string;
    label: string;
    hintText?: string;
    errorMessage?: string;
    required?: boolean;
    onFocus?: FocusEventHandler;
    onKeyDown?: KeyboardEventHandler;
}

export const ComFieldInput = React.forwardRef<HTMLDivElement, ComFieldInputProps & HTMLAttributes<{}>>(
    (props: ComFieldInputProps & HTMLAttributes<{}>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const { controlId, label, hintText, errorMessage, required, onFocus, onKeyDown, ...rest } = props;
        const labelId = `${controlId}-label`;
        const hintTextId = `${controlId}-hint`;
        const errorId = `${controlId}-error`;

        return (
            <ComFieldWrapper
                labelId={labelId}
                label={label}
                hintTextId={hintTextId}
                hintText={hintText}
                errorId={errorId}
                errorMessage={errorMessage}
                required={required}
                ref={ref}
            >
                <input
                    {...rest}
                    aria-labelledby={hintText ? `${labelId} ${hintTextId}` : labelId}
                    aria-invalid={!!errorMessage}
                    aria-errormessage={errorMessage}
                    aria-describedby={errorMessage ? errorId : ''}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                />
            </ComFieldWrapper>
        );
    },
);
