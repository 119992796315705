import React, { useState, useEffect, useContext, useRef } from 'react';
import { CommonConstants } from '../../CommonConstants';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { Row, Col } from 'reactstrap';
import { personalisationContext } from '../../providers/personalisation';
import { ContentItem } from '@exporter-services/common-ui';
import CommoditiesSearch, { Commodity } from '../../controls/search/CommoditiesSearch';
import { CommoditySearchItem } from '../../controls/search/CommoditySearchItem';
import RadioButtonGroup, { RadioButtonGroupItem } from '../../controls/radioButtonGroup/RadioButtonGroup';
import { scrollTo } from '../../utils/scrollUtils';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { TooltipText } from '../../controls/tooltip/TooltipText';
import { IndustryOption, SectorOption } from '../../models/IndustrySector';

interface XptExportPlanStep1Props {
    data: {
        item?: ContentItem;
    };

    industryCode: string;
    sectorCode: string;
    hsCode: string;
    setIndustryCode: Function;
    setSectorCode: Function;
    setHsCode: Function;
    setMarketCode: Function;
    setIsBackAvailable: Function;
    setIsNextAvailable: Function;
}

const XptExportPlanStep1 = (props: XptExportPlanStep1Props) => {
    const [exportType, setExportType] = useState<RadioButtonGroupItem>(null);
    const [selectedIndustry, setSelectedIndustry] = useState<IndustryOption>(null);
    const [selectedSector, setSelectedSector] = useState<SectorOption>(null);
    const [industries, setIndustries] = useState<IndustryOption[]>([]);
    const [showSectorsList, setShowSectorsList] = useState<boolean>(false);
    const [strongMarketEntity, setStrongMarketEntity] = useState(null);
    const [selectedHsCode, setSelectedHsCode] = useState('');
    const { personalisationState } = useContext(personalisationContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [showIndustriesList, setShowIndustriesList] = useState<boolean>(false);
    const [showCommoditiesSearch, setShowCommoditiesSearch] = useState<boolean>(false);
    const [selectSectorViaHref, setSelectSectorViaHref] = useState<boolean>(false);
    const industryDropdownRef = useRef(null);
    const sectorDropdownRef = useRef(null);
    const commoditiesDropdownRef = useRef(null);
    const [hsCodesHelpText, setHsCodesHelpText] = useState<[string, string]>(null);
    const { isMobile, isTablet } = useGetDisplayType();

    const productSearchFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
        Title: CommonConstants.PRODUCTS_RADIOBUTTON_TITLE,
        Value: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
    };
    const serviceSelectionFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
        Title: CommonConstants.SERVICES_RADIOBUTTON_TITLE,
        Value: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
    };

    useEffect(() => {
        props.setIsBackAvailable(false);
        props.setIsNextAvailable(false);
        extractIndustries();
        extractHSCodesHelpText();
        setStrongMarketEntity(
            props.data.item?.elements.step1_selection_filter['linkedItems'][1]?.elements.dropdown_item['linkedItems'].filter(
                (v) => v.elements.industry_sector?.value?.[0]?.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME,
            )[0],
        );
    }, [personalisationState.hsCode, personalisationState.industryCode, personalisationState.sectorCode]);

    useEffect(() => {
        if (selectSectorViaHref) {
            const params = new URLSearchParams(window.location.search);
            const sectorParam = params.get('sector');
            const sectorParamValue = replaceAll(sectorParam, '-', '_');
            changeSectorByCodeName(sectorParamValue);
        }
    }, [selectSectorViaHref, selectedIndustry]);

    useEffect(() => {
        (async () => {
            if (window.location.href.indexOf('?') > -1) {
                await urlParamsOverride();
            }
        })();
    }, [window.location.search, industries?.length]);

    const urlParamsOverride = async () => {
        const params = new URLSearchParams(window.location.search);
        const industryParam = params.get('industry');
        const sectorParam = params.get('sector');
        const hsCodeParam = params.get('hscode');
        if (industryParam && sectorParam) {
            searchFilterRadioButtonItemSelected(serviceSelectionFilterRadioButtonGroupItem);
            const industryParamValue = replaceAll(industryParam, '-', '_');
            changeIndustryByCodeName(industryParamValue);
            setSelectSectorViaHref(true);
        } else {
            searchFilterRadioButtonItemSelected(productSearchFilterRadioButtonGroupItem);
            if (hsCodeParam) {
                setSelectedHsCode(hsCodeParam);
            }
        }
    };

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    const extractHSCodesHelpText = () => {
        let hsCodesHelpTextResult = props.data.item?.elements.step1_selection_filter['linkedItems'].find((item) => {
            if (item?.system?.codename === CommonConstants.KONTENT_HSCODES_TOOLTIP_CODENAME) {
                return item;
            }
            return null;
        });

        if (hsCodesHelpTextResult) {
            let header = hsCodesHelpTextResult.elements.header?.value;
            let description = hsCodesHelpTextResult.elements.description?.value;
            if (header && description) {
                setHsCodesHelpText([header, description]);
            }
        }
    };

    const extractIndustries = () => {
        let industriesToCollect: IndustryOption[] = [];
        props.data.item?.elements.step1_selection_filter['linkedItems'][0]?.elements.dropdown_item['linkedItems'].forEach((i) => {
            let sectors: SectorOption[] = [];

            i.elements.filtered_component['linkedItems']
                .filter((fc) => fc?.elements.industry_sector?.value?.length > 0)
                .forEach((fc) => {
                    let sector: SectorOption = {
                        title: fc?.elements.title?.value,
                        codeName: fc?.elements.industry_sector?.value[0]?.codename,
                    };
                    sectors.push(sector);
                });

            let industry: IndustryOption = {
                title: i.elements.title?.value,
                codeName: i.elements.industry_sector?.value[0]?.codename,
                sectors: [...sectors],
            };

            industriesToCollect.push(industry);
        });

        setIndustries(industriesToCollect);
        hydrateFilters(industriesToCollect);
    };

    const getValidSectorCodeName = (industry: IndustryOption, sectorToValidate: string) => {
        let sectorInIndustrySubSectors = industry?.sectors?.find((s) => s.codeName === sectorToValidate);
        if (!sectorInIndustrySubSectors) {
            return sectorToValidate === industry?.codeName ? { codeName: sectorToValidate, title: null } : null;
        } else return sectorInIndustrySubSectors;
    };

    const hydrateFilters = (industryOptions: IndustryOption[]) => {
        setSelectedIndustry(null);
        setSelectedSector(null);
        props.setIsNextAvailable(false);
        setSelectedHsCode(personalisationState.hsCode);
        let industry = industryOptions.find((i) => i.codeName === personalisationState.industryCode);
        if (industry && !personalisationState.hsCode) {
            props.setIsNextAvailable(true);

            if (industry.sectors?.length > 0) {
                let sector = getValidSectorCodeName(industry, personalisationState.sectorCode);
                if (!sector) {
                    props.setIsNextAvailable(true);
                    setSelectedIndustry(null);
                    setSelectedSector(null);
                } else if (sector) {
                    setSelectedIndustry(industry);
                    setSelectedSector(sector);
                    props.setIsNextAvailable(true);
                }
            } else {
                let selectedSector: SectorOption = { title: industry?.title, codeName: industry?.codeName };
                setSelectedIndustry(industry);
                setSelectedSector(selectedSector);
                props.setIsNextAvailable(true);
            }
        } else {
            setSelectedIndustry(null);
            setSelectedSector(null);
        }

        setLoading(false);
    };

    const changeIndustryByCodeName = (codeName) => {
        if (!codeName) return;
        let industry = industries.find((i) => i.codeName === codeName);
        props.setIndustryCode(codeName);
        props.setSectorCode('');
        props.setHsCode('');
        props.setMarketCode('');

        setSelectedIndustry(industry);
        setSelectedSector(null);
        setSelectedHsCode('');
        if (industry?.sectors?.length > 0) {
            setShowSectorsList(true);
            props.setIsNextAvailable(false);
        } else {
            setShowSectorsList(false);
            let selectedSector: SectorOption = { title: industry?.title, codeName: industry?.codeName };
            setSelectedSector(selectedSector);
            props.setSectorCode(codeName);
            props.setIsNextAvailable(true);
        }
    };

    const changeSectorByCodeName = (codeName) => {
        if (!codeName) return;
        props.setSectorCode(codeName);
        props.setHsCode('');

        let sector = selectedIndustry?.sectors.find((s) => s.codeName === codeName);
        if (sector) {
            setSelectedSector(sector);
            props.setIsNextAvailable(true);
        }
    };

    const changeIndustry = (event) => {
        let codeName = event.target.value;
        changeIndustryByCodeName(codeName);
    };

    const changeSector = (event) => {
        let codeName = event.target.value;
        changeSectorByCodeName(codeName);
    };

    const onCommodityClick = (selectedOption: Commodity) => {
        if (!selectedOption) {
            props.setIsNextAvailable(false);
            return;
        }

        setSelectedHsCode(selectedOption?.Code);
        setSelectedIndustry(null);
        setSelectedSector(null);
        props.setHsCode(selectedOption?.Code);
        if (selectedOption?.Code !== personalisationState.hsCode) {
            props.setMarketCode('');
        }

        props.setIsNextAvailable(true);
    };

    const isIndustrySelected = (industry) => {
        let determineIsIndustrySelected = selectedIndustry && industry.codeName === selectedIndustry.codeName;
        return determineIsIndustrySelected;
    };

    const isSectorSelected = (sector) => {
        return selectedSector && sector.codeName === selectedSector.codeName;
    };

    const industryDropdownFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(industryDropdownRef);
        }
    };

    const sectorDropdownFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(sectorDropdownRef);
        }
    };

    const commoditiesDropdownFocus = () => {
        if (isMobile || isTablet) {
            // fix for third party dropdown control scroll issue
            setTimeout(() => {
                scrollTo(commoditiesDropdownRef);
            }, 100);
        }
    };

    const renderIndustries = (paramIndustryDropdownRef: any) => {
        return (
            showIndustriesList && (
                <div className="filtergroup-form-field-container" ref={paramIndustryDropdownRef}>
                    <label className="field-label" htmlFor="industry">
                        Select industry
                    </label>
                    <select id="industry" name="industry" onChange={changeIndustry} onFocus={industryDropdownFocus}>
                        {!selectedIndustry && (
                            <option key="select_an_industry" value="">
                                Select an industry
                            </option>
                        )}
                        {industries.map((industry) => (
                            <option
                                className={`${isIndustrySelected(industry) ? 'selected' : ''} filter-item-select-option`}
                                key={industry.codeName}
                                value={industry.codeName}
                                selected={isIndustrySelected(industry)}
                            >
                                {industry.title}
                            </option>
                        ))}
                    </select>
                </div>
            )
        );
    };

    const getSearchFilterRadioButtonSelection = () => {
        if (exportType) {
            if (exportType.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
                return CommonConstants.PRODUCTS_RADIOBUTTON_VALUE;
            } else {
                return CommonConstants.SERVICES_RADIOBUTTON_VALUE;
            }
        }

        let industry = industries.find((i) => i.codeName === personalisationState.industryCode);
        let sector = getValidSectorCodeName(industry, personalisationState.sectorCode);
        if (personalisationState.hsCode || !industry || !sector) {
            return CommonConstants.PRODUCTS_RADIOBUTTON_VALUE;
        } else {
            return CommonConstants.SERVICES_RADIOBUTTON_VALUE;
        }
    };

    function searchFilterRadioButtonItemSelected(item: RadioButtonGroupItem) {
        setExportType(item);
        props.setIsNextAvailable(false);
        if (item.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
            setShowSectorsList(false);
            setShowIndustriesList(false);
            setSelectedIndustry(null);
            setSelectedSector(null);
            setShowCommoditiesSearch(true);
        } else {
            setShowIndustriesList(true);
            if (selectedIndustry?.sectors?.length > 0) {
                setShowSectorsList(true);
                props.setIsNextAvailable(true);
            } else if (!!selectedIndustry && selectedIndustry?.codeName === selectedSector?.codeName) {
                props.setIsNextAvailable(true);
            }
            setSelectedHsCode(null);
            setShowCommoditiesSearch(false);
        }
    }

    const renderProducts = (paramCommoditiesDropdownRef: any) => {
        return (
            showCommoditiesSearch && (
                <div className="filtergroup-form-field-container" ref={paramCommoditiesDropdownRef}>
                    <label className="field-label" htmlFor="commodities-search">
                        {strongMarketEntity?.elements.information_for?.value}
                    </label>
                    <CommoditiesSearch
                        item={strongMarketEntity}
                        searchItem={strongMarketEntity?.elements.filtered_component?.linkedItems[0] as CommoditySearchItem}
                        onClick={onCommodityClick}
                        PnPFilter={false}
                        industryCode={CommonConstants.PRODUCTS_CODENAME}
                        commodityCode={selectedHsCode}
                        isMarketSearch={true}
                        ariaLabel={strongMarketEntity?.elements.information_for?.value}
                        onFocus={commoditiesDropdownFocus}
                    />
                    {hsCodesHelpText && <TooltipText data={{ header: hsCodesHelpText[0], description: hsCodesHelpText[1] }} />}
                </div>
            )
        );
    };

    const renderSectors = (paramSectorDropdownRef: any) => {
        return (
            selectedIndustry &&
            showSectorsList && (
                <div className="filtergroup-form-field-container" ref={paramSectorDropdownRef}>
                    <label className="field-label" htmlFor="sector">
                        Select sector
                    </label>
                    <select id="sector" name="sector" onChange={changeSector} onFocus={sectorDropdownFocus}>
                        {!selectedSector && (
                            <option key="select_a_sector" value="">
                                Select a sector
                            </option>
                        )}
                        {selectedIndustry.sectors.map((sector) => (
                            <option
                                className={`${isSectorSelected(sector) ? 'selected' : ''}`}
                                key={sector.codeName}
                                value={sector.codeName}
                                selected={isSectorSelected(sector)}
                            >
                                {sector.title}
                            </option>
                        ))}
                    </select>
                </div>
            )
        );
    };

    return (
        <div className="exportplanstep1">
            <h2 className="sr-only">{props.data.item?.elements.step1_description.value}</h2>
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <Row className="mt-3">
                    <Col>
                        <RadioButtonGroup
                            items={[productSearchFilterRadioButtonGroupItem, serviceSelectionFilterRadioButtonGroupItem]}
                            legendAsLabel={true}
                            selectedValue={getSearchFilterRadioButtonSelection()}
                            name={'looking_export'}
                            label={CommonConstants.EXPORT_TYPE_SELECTION_LABEL}
                            onChangeHandler={(e, itm) => searchFilterRadioButtonItemSelected(itm)}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        {renderIndustries(industryDropdownRef)}
                        {renderSectors(sectorDropdownRef)}
                    </Col>
                </Row>

                <Row>
                    <Col md="12">{renderProducts(commoditiesDropdownRef)}</Col>
                </Row>
            </LoadingOverlay>
        </div>
    );
};

export default XptExportPlanStep1;
