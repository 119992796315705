import React, { useState } from 'react';
import { FilterClear, FilterCheckbox, FilterItem, FilterItemInteraction } from '@exporter-services/common-ui';
import '../../styles/_filterPages.scss';
import { CommonConstants } from '../../CommonConstants';
import { StringFilterDictionary } from './StringFilterDictionary';

export interface ToolsAndTemplatesFilterCriteria {
    itemTypes?: StringFilterDictionary;
    resultsCount?: number;
}

export interface FilterMainPanelProps {
    data?: {
        itemTypesFilterItems?: FilterItem[];
    };
    onChange?: (filterCriteria: ToolsAndTemplatesFilterCriteria) => void;
}

const FilterToolsAndTemplatesPanel = (props: FilterMainPanelProps) => {
    const FILTER_DEFAULT_STATE: ToolsAndTemplatesFilterCriteria = {
        itemTypes: {},
    };

    let [filterCriteria, setFilterCriteria] = useState<ToolsAndTemplatesFilterCriteria>(FILTER_DEFAULT_STATE);

    let [clearState, setClearState] = useState<boolean>(false);

    const handleOnClearFilter = () => {
        setClearState(true);
        filterCriteria = JSON.parse(JSON.stringify(FILTER_DEFAULT_STATE));

        let newState = Object.assign({}, FILTER_DEFAULT_STATE);
        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleItemTypesFilterChange = (filterItem: FilterItemInteraction) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);

        newState.itemTypes[filterItem.value] = filterItem.selected;

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    return (
        <div className="filter-main-panel">
            {props.data.itemTypesFilterItems?.map((typeFilterItem: FilterItem, index: number) => {
                return (
                    <>
                        <hr className="filter-main-panel-separator" />
                        <FilterCheckbox
                            data={{
                                id: 'itemType_' + index,
                                value: typeFilterItem.value,
                                label: typeFilterItem.label,
                                count: typeFilterItem.count,
                                checked: filterCriteria.itemTypes[typeFilterItem.value] ?? false,
                                clearState: clearState,
                            }}
                            onChange={handleItemTypesFilterChange}
                        />
                    </>
                );
            })}

            <hr className="filter-main-panel-separator" />
            <FilterClear data={{ label: CommonConstants.CLEAR_FILTERS_LABEL }} onClick={handleOnClearFilter} />
        </div>
    );
};

export default FilterToolsAndTemplatesPanel;
