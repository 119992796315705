import { MvTaxesAndDuties } from './MvTaxesAndDuties';
import { MvProduct } from './MvProduct';
import { LawsAndRegsDataSourceType } from './LawsAndRegsDataSourceType';
import { NewsFilterItem } from './NewsFilterItem';
import { IContentItem } from '@kontent-ai/delivery-sdk';
import { MarketSearchToolSearchType } from '../services/MarketService';
import { StrongMarket } from './StrongMarket';

export interface IAJAXStateInfo {
    retrieved: boolean;
    pending?: boolean;
    data?: any;
}

export class LawsAndRegsDataSourceInfo implements IAJAXStateInfo {
    retrieved: boolean;
    data?: LawsAndRegsDataSourceType = undefined;
}

export class MvTaxesAndDutiesInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: MvTaxesAndDuties = undefined;
}

export class CurrentMarketNameInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: string = undefined;
}

export class CommoditiesInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: any[] = undefined;
}

export class ExportPlanDownloadInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: any = undefined;
}

export class CurrentMarketSearchToolSearchData {
    results: StrongMarket[] | IContentItem[];
    timeTag: number;
    type: MarketSearchToolSearchType;
}

export class TradeAgreementMarkets {
    retrieved: boolean;
    pending: boolean;
    data?: string[] = undefined;
}

export class MarketImages {
    retrieved: boolean;
    pending: boolean;
    data?: IContentItem[] = undefined;
}

export class PageInfo {
    codename: string;
}

export class MvProductInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: MvProduct = undefined;
}

export class ProductServiceSelectionInfo {
    industryCode: string;
    sectorCode: string;
    hsCode: string;
    market: string;
}

export type GetCurrentMarketNameRequest = {
    params: {
        market: string;
    };
};

export type ExportPlanDownloadRequest = { industryCode: string; sectorCode: string; hsCode: string; marketCode: string };

export type UserType = { name: string; codename: string };

export class UserGeolocation {
    code: string;
    isOffshore: boolean;
}

export class UserGeolocationInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data: UserGeolocation;
}

export class NewsFilterInfo implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: NewsFilterItem[] = undefined;
}

export class KenticoSectorTaxonomyGroup implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: KenticoIndustrySectorCollection = undefined;
}

export class KenticoRegionMarketMapping implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: KenticoRegion[] = undefined;
}

export class KenticoPageCategories implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: Array<string>;
}

export class KenticoUserTypes implements IAJAXStateInfo {
    retrieved: boolean;
    pending: boolean;
    data?: Array<UserType>;
}

export type KenticoIndustrySectorCollection = {
    industryCollection: KenticoIndustryOption[];
    sectorCollectionFlat: KenticoSectorOptionFlat;
    industryDictionary?: KenticoIndustryDictionary;
};

export type KenticoIndustryOption = {
    code: string;
    description: string;
    id: number;
};

export type KenticoRegion = {
    code: string;
    description: string;
    markets: KenticoMarket[];
};

export type KenticoMarket = {
    code: string;
    description: string;
};

export type KenticoSectorOptionFlat = {
    //Nested subsectors converted to flat list
    [code: string]: { description: string; industryId: number; industryCode?: string };
};

export type KenticoIndustryDictionary = {
    //Nested subsectors converted to flat list
    [code: string]: { description: string };
};

export enum OnLoadPersonalisationType {
    None,
    Querystring,
    LocalStorage,
    DefaultLocalStorage,
}

export interface OnLoadPersonalisationInfo {
    industry: string;
    sector: string;
    url: string;
    isSet: boolean;
    type: OnLoadPersonalisationType;
}
