import React, { MutableRefObject, ReactNode } from 'react';
import './ComFormField.scss';

interface ComFieldWrapperProps {
    labelId: string;
    label: string;
    hintTextId: string;
    hintText?: string;
    errorId?: string;
    errorMessage?: string;
    required?: boolean;
    children?: ReactNode;
}

export const ComFieldWrapper = React.forwardRef((props: ComFieldWrapperProps, ref: MutableRefObject<HTMLDivElement>) => {
    const { labelId, label, hintTextId, hintText, errorId, errorMessage, required, children } = props;

    return (
        <div className="field-wrapper" ref={ref}>
            <label className="field-label" id={labelId}>{`${label}${required ? ' *' : ''}`}</label>
            {hintText && <p className="field-hint-text" id={hintTextId} dangerouslySetInnerHTML={{ __html: hintText }}></p>}
            <div className={`${errorMessage ? 'error-wrapper' : ''}`}>{children}</div>
            {errorMessage && (
                <p className="field-error-message" id={errorId} aria-live="polite">
                    {errorMessage}
                </p>
            )}
        </div>
    );
});
