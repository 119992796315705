import React, { useEffect, useRef, useState } from 'react';
import { ContentItem, FilterItem, PaginationLinks, NewsPathwayTile, NestedFilterItem } from '@exporter-services/common-ui';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import '../../styles/_filterPages.scss';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import FilterNewsPanel, { NewsFilterCriteria } from './FilterNewsPanel';
import _ from 'lodash';
import { KenticoRegionMarketMapping, KenticoSectorTaxonomyGroup, NewsFilterInfo } from '../../models/ReduxModels';
import { NewsFilterItem } from '../../models/NewsFilterItem';
import { getNewsDataFromKentico } from '../../providers/reducers/newsFilterSlice';
import { formatDate } from '../../utils/dateUtils';
import { PageName } from '../../models/PageName';
import { newsFilterUtils, stringFilterDictionary } from './newsFilterUtils';
import { descCountDictionary, filterUtils, marketDictionary, uniqueContentIdDictionary } from '../../utils/filterPanelUtils';
import { getKenticoRegionMarketMapping, getKenticoSectorTaxonomyGroup } from '../../providers/reducers/taxonomySlice';
import { CommonConstants } from '../../CommonConstants';
import { scrollTo } from '../../utils/scrollUtils';

export interface FilteredNewsResultsContainerProps {
    data: {
        item: any;
    };
}

const FilteredNewsResultsContainer = (props: FilteredNewsResultsContainerProps) => {
    const [articleList, setArticleList] = useState<NewsFilterItem[]>([]);
    const [filteredArticles, setFilteredArticles] = useState<NewsFilterItem[]>(null);
    const [currentPageArticles, setCurrentPageArticles] = useState<NewsFilterItem[]>(null);

    const pageSize = CommonConstants.PAGE_SIZE;
    const [filteredCount, setFilteredCount] = useState<number>(0);

    const [regionMarketPanelList, setRegionMarketPanelList] = useState<NestedFilterItem[]>(null);
    const fullMarketRegionDictionary = useRef<marketDictionary>({});
    const fullRegionMarketList = useRef<uniqueContentIdDictionary>({});

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [articleTypeList, setArticleTypeList] = useState<FilterItem[]>(null);
    const [industrySectorList, setIndustrySectorList] = useState<NestedFilterItem[]>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const fullArticleTypeList = useRef<descCountDictionary>({});
    const fullSectorFlatList = useRef<uniqueContentIdDictionary>({});
    const sectorTaxonomyGroup = useAppSelector<KenticoSectorTaxonomyGroup>((state) => state.taxonomy.sectorTaxonomyGroup);
    const regionMarketMapping = useAppSelector<KenticoRegionMarketMapping>((state) => state.taxonomy.regionMarketMapping);

    const [articleListRetrieved, setArticleListRetrieved] = useState<boolean>(false);

    const newsArticles = useAppSelector<NewsFilterInfo>((state) => state.newsFilter.newsFilterInfo);
    const refResultCountElement = useRef(null);
    const resultsContainerRef = useRef<HTMLDivElement>(null);

    let sortList = [
        { value: 'Newest', label: 'Newest' },
        { value: 'Oldest', label: 'Oldest' },
    ];

    //#region Get article list, market list, sector list
    useEffect(() => {
        dispatch(getKenticoSectorTaxonomyGroup());
        dispatch(getKenticoRegionMarketMapping());
        dispatch(getNewsDataFromKentico());
    }, []);

    useEffect(() => {
        if (newsArticles?.retrieved && !newsArticles.pending && !newsArticles.data) {
            setLoading(false);
            throw new Error('Unable to retrieve news data.');
        }

        if (newsArticles?.retrieved && newsArticles.data) {
            setArticleList(newsArticles.data);
            setFilteredArticles(newsArticles.data);

            let totalCount = newsArticles?.data ? newsArticles.data.length : 0;
            setPaginationParameters(totalCount);
            setTotalPages(Math.ceil(totalCount / pageSize));

            //set first page results
            let pagedItems = _.drop(newsArticles.data, 0).slice(0, pageSize);
            setCurrentPageArticles(pagedItems);
            setCurrentPage(1);
            setArticleListRetrieved(true);
        }
    }, [newsArticles.retrieved]);

    useEffect(() => {
        let articleTypeCountDict = newsFilterUtils.getArticleTypeList(articleList);
        fullArticleTypeList.current = articleTypeCountDict;

        let tempFilterItemList = newsFilterUtils.getArticleTypeFilterList(articleTypeCountDict);
        setArticleTypeList(tempFilterItemList);
    }, [articleListRetrieved]);

    useEffect(() => {
        if (articleListRetrieved && sectorTaxonomyGroup.retrieved) {
            let tempIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(articleList);
            fullSectorFlatList.current = tempIndustrySectorFlatList;
            let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(tempIndustrySectorFlatList, sectorTaxonomyGroup.data);
            let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);
            setIndustrySectorList(tempFilterList);
        }
    }, [sectorTaxonomyGroup.retrieved, articleListRetrieved]);

    useEffect(() => {
        if (regionMarketMapping.retrieved && articleListRetrieved) {
            let tempMarketRegionDictionary = filterUtils.getMarketRegionDictionary(regionMarketMapping.data);
            fullMarketRegionDictionary.current = tempMarketRegionDictionary;
            let tempMarketContentItemsDictionary = filterUtils.getMarketContentItemsDictionary(articleList);
            fullRegionMarketList.current = tempMarketContentItemsDictionary;
            let tempRegionMarketDictionary = filterUtils.getRegionMarketDictionary(
                tempMarketContentItemsDictionary,
                tempMarketRegionDictionary,
                regionMarketMapping.data,
            );

            let regionMarketNestedItem = filterUtils.getRegionMarketNestedPanelList(tempRegionMarketDictionary);
            setRegionMarketPanelList(regionMarketNestedItem);
            setLoading(false);
        }
    }, [regionMarketMapping.retrieved, articleListRetrieved]);

    const setPaginationParameters = (resultsCount: number) => {
        setFilteredCount(resultsCount);
        setTotalPages(Math.ceil(resultsCount / pageSize));
    };

    //#endregion

    //#region getArticles for the page
    const getArticles = (pageNumber: number) => {
        if (!filteredArticles) return;

        let offset = (pageNumber - 1) * pageSize;
        let pagedItems = _.drop(filteredArticles, offset).slice(0, pageSize);
        setCurrentPageArticles(pagedItems);
        setCurrentPage(pageNumber);
    };

    const scrollToPageTop = () => {
        scrollTo(refResultCountElement);
        if (resultsContainerRef?.current) resultsContainerRef.current.focus();
    };

    const onFirstPageClick = () => {
        getArticles(1);
        scrollToPageTop();
    };

    const onPreviousPageClick = () => {
        if (currentPage > 1) {
            getArticles(currentPage - 1);
        }
        scrollToPageTop();
    };

    const onNextPageClick = () => {
        if (currentPage < totalPages) {
            getArticles(currentPage + 1);
        }
        scrollToPageTop();
    };

    const onLastPageClick = () => {
        getArticles(totalPages);
        scrollToPageTop();
    };

    function onPageNumberClick(pageNumber: number) {
        getArticles(pageNumber);
        scrollToPageTop();
    }

    //#endregion

    //#region Apply Filters
    const onFilterChange = (filterCriteria: NewsFilterCriteria) => {
        let tempFilteredList = newsFilterUtils.applyArticleTypesFilter(filterCriteria.articleTypeCriteriaList, articleList);

        tempFilteredList = filterUtils.applySectorFilterToContentItemsList(filterCriteria.industrySectorCriteriaList, tempFilteredList);

        tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(filterCriteria.regionMarketCriteriaList, tempFilteredList);

        tempFilteredList = newsFilterUtils.applySortCriteria(filterCriteria.sortCriteria, tempFilteredList);

        setFilteredArticles([...tempFilteredList]);
        setFilteredCount(tempFilteredList.length);

        //Update left side FilterPanels
        updateFilterPanels(filterCriteria);

        //set results to 1st page of pagination
        let pagedItems = _.drop(tempFilteredList, 0).slice(0, pageSize);
        setCurrentPageArticles(pagedItems);
        setCurrentPage(1);
        setPaginationParameters(tempFilteredList.length);
    };

    const updateFilterPanels = (filterCriteria: NewsFilterCriteria) => {
        updateArticleTypeFilterPanel(filterCriteria);
        updateIndustrySectorFilterPanel(filterCriteria);
        updateRegionMarketFilterPanel(filterCriteria);
    };

    const updateArticleTypeFilterPanel = (filterCriteria: NewsFilterCriteria) => {
        let tempFilteredList = [...articleList] as ContentItem[];

        if (checkFilterSelectionCount(filterCriteria.industrySectorCriteriaList) > 0) {
            tempFilteredList = filterUtils.applySectorFilterToContentItemsList(filterCriteria.industrySectorCriteriaList, [...tempFilteredList]);
        }

        if (checkFilterSelectionCount(filterCriteria.regionMarketCriteriaList) > 0) {
            tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(filterCriteria.regionMarketCriteriaList, [...tempFilteredList]);
        }

        updateArticleFilterPanelListCount([...tempFilteredList]);
    };

    const updateRegionMarketFilterPanel = (filterCriteria: NewsFilterCriteria) => {
        let tempFilteredList = [...articleList] as ContentItem[];

        if (checkFilterSelectionCount(filterCriteria.industrySectorCriteriaList) > 0) {
            tempFilteredList = filterUtils.applySectorFilterToContentItemsList(filterCriteria.industrySectorCriteriaList, [...tempFilteredList]);
        }

        if (checkFilterSelectionCount(filterCriteria.articleTypeCriteriaList) > 0) {
            tempFilteredList = newsFilterUtils.applyArticleTypesFilter(filterCriteria.articleTypeCriteriaList, [...tempFilteredList]);
        }

        updateRegionMarketFilterPanelListCount([...tempFilteredList]);
    };

    const updateIndustrySectorFilterPanel = (filterCriteria: NewsFilterCriteria) => {
        let tempFilteredList = [...articleList] as ContentItem[];

        if (checkFilterSelectionCount(filterCriteria.regionMarketCriteriaList) > 0) {
            tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(filterCriteria.regionMarketCriteriaList, [...tempFilteredList]);
        }

        if (checkFilterSelectionCount(filterCriteria.articleTypeCriteriaList) > 0) {
            tempFilteredList = newsFilterUtils.applyArticleTypesFilter(filterCriteria.articleTypeCriteriaList, [...tempFilteredList]);
        }

        updateIndustrySectorFilterPanelListCount([...tempFilteredList]);
    };

    const checkFilterSelectionCount = (criteria: stringFilterDictionary) => {
        let count = 0;
        if (!criteria || Object.keys(criteria).length === 0) return 0;
        Object.keys(criteria).forEach((item: string) => {
            if (criteria[item]) {
                count++;
            }
        });
        return count;
    };

    const updateArticleFilterPanelListCount = (filteredNewsList: NewsFilterItem[]) => {
        let articleTypeList = newsFilterUtils.getUpdatedArticleTypeListCount(filteredNewsList, fullArticleTypeList.current);
        let tempFilterItemList = newsFilterUtils.getArticleTypeFilterList(articleTypeList);

        setArticleTypeList(tempFilterItemList);
    };

    const updateRegionMarketFilterPanelListCount = (filteredEventList: NewsFilterItem[]) => {
        let filteredRegionMarketFlatList = filterUtils.getMarketContentItemsDictionary(filteredEventList);
        let updatedRegionMarketFlatList = filterUtils.updateRegionMarketFlatList(filteredRegionMarketFlatList, fullRegionMarketList.current);

        let tempRegionMarketDictionaryList = filterUtils.getRegionMarketDictionary(
            updatedRegionMarketFlatList,
            fullMarketRegionDictionary.current,
            regionMarketMapping.data,
        );
        let tempFilterList = filterUtils.getRegionMarketNestedPanelList(tempRegionMarketDictionaryList);

        setRegionMarketPanelList(tempFilterList);
    };

    const updateIndustrySectorFilterPanelListCount = (filteredNewsList: NewsFilterItem[]) => {
        let filteredIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(filteredNewsList);
        let updatedIndustrySectorFlatList = filterUtils.updateIndustrySectorFlatList(filteredIndustrySectorFlatList, fullSectorFlatList.current);
        let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(updatedIndustrySectorFlatList, sectorTaxonomyGroup.data);
        let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);

        setIndustrySectorList(tempFilterList);
    };
    //#endregion

    const getFallbackImage = (): string => {
        return props.data?.item?.elements.fallback_image?.value[0].url;
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            <Row>
                <Col>
                    {filteredCount > 0 ? (
                        <h2 className="results-count-header" ref={refResultCountElement}>
                            Showing <strong>{filteredCount}</strong> {filteredCount === 1 ? 'news item' : 'news items'}
                        </h2>
                    ) : (
                        <h2 className="results-count-header">No news items to show</h2>
                    )}
                </Col>
            </Row>
            <Row className="filtered-results-container">
                <Col md="4">
                    {articleListRetrieved &&
                        sectorTaxonomyGroup.retrieved &&
                        articleTypeList &&
                        (regionMarketPanelList?.length > 0 || industrySectorList?.length > 0) && (
                            <FilterNewsPanel
                                data={{
                                    articleTypeList: articleTypeList,
                                    regionMarketList: regionMarketPanelList,
                                    industrySectorList: industrySectorList,
                                    sortList: sortList,
                                }}
                                onChange={onFilterChange}
                            />
                        )}
                </Col>
                <Col md="8">
                    {currentPageArticles?.length > 0 && (
                        <div ref={resultsContainerRef} tabIndex={-1}>
                            {currentPageArticles.map((a: NewsFilterItem) => (
                                <NewsPathwayTile
                                    key={a.id}
                                    data={{
                                        id: a.id,
                                        title: a.title,
                                        lastUpdated: formatDate(a.lastUpdated),
                                        category: newsFilterUtils.getArticleCategory(a.articleType),
                                        categoryIconClass: newsFilterUtils.getArticleIconClass(a.articleType),
                                        categoryTagClass: newsFilterUtils.getArticleTagClass(a.articleType),
                                        imageLink: a.imageLink ? a.imageLink : getFallbackImage(),
                                        imageAltDescription: a.imageCaption ? a.imageCaption : a.title,
                                        linkUrl: `/${PageName.NewsAndEvents}/${PageName.NewsFilterPage}/` + a.urlSlug,
                                    }}
                                ></NewsPathwayTile>
                            ))}
                        </div>
                    )}
                    <div className="pagination-links-container" data-testid={'pagination'}>
                        {totalPages > 0 && (
                            <PaginationLinks
                                data={{
                                    totalPages: totalPages,
                                    currentPage: currentPage,
                                    pageSize: pageSize,
                                    onPageNumberClick: onPageNumberClick,
                                    onFirstPageClick: onFirstPageClick,
                                    onLastPageClick: onLastPageClick,
                                    onNextPageClick: onNextPageClick,
                                    onPreviousPageClick: onPreviousPageClick,
                                }}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </LoadingOverlay>
    );
};

export default FilteredNewsResultsContainer;
