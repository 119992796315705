import React, { useEffect, useState, useRef, useCallback } from 'react';
import { client } from '../../config';
import '../../styles/_filterPages.scss';
import './MarketExplorerSearchContainer.scss';
import { IContentItem, Responses } from '@kontent-ai/delivery-sdk';
import {
    ContentItem,
    ImageTilePathway,
    ImageTilePathwayItem,
    NestedFilterItem,
    PageLayoutType,
    PaginationLinks,
    getImageItemDescription,
    getImageItemByMarketCode,
    getImageItemUrl,
    getImageItemSystemId,
} from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { PageName } from '../../models/PageName';
import { Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
    CurrentMarketSearchToolSearchData,
    KenticoRegionMarketMapping,
    KenticoSectorTaxonomyGroup,
    MarketImages,
    TradeAgreementMarkets,
} from '../../models/ReduxModels';
import { getKenticoRegionMarketMapping, getKenticoSectorTaxonomyGroup } from '../../providers/reducers/taxonomySlice';
import { FilterContentKey, filterUtils, uniqueContentIdDictionary } from '../../utils/filterPanelUtils';
import FilterMarketsPanel, { MarketsFilterCriteria } from './FilterMarketsPanel';
import { getKenticoMarketImageData, getKenticoMarketTradeAgreements, getMarketSearchToolSearchData } from '../../providers/reducers/marketSlice';
import { drop, intersection } from 'lodash';
import { MarketSearchRequest, MarketSearchToolSearchType } from '../../services/MarketService';
import { CommonConstants } from '../../CommonConstants';
import { scrollTo } from '../../utils/scrollUtils';
import { getMarketDisplayName } from '../../utils/marketSearchUtils';

type stringFilterDictionary = { [id: string]: boolean };

interface MarketOption extends IContentItem {
    Id: string;
    Code: string;
    Label: string;
    ImageUrl: string;
    AltText: string;
    LinkUrl: string;
}

const MarketExplorerSearchContainer = () => {
    const [filteredMarkets, setFilteredMarkets] = useState<MarketOption[]>();
    const [imageTilePathwayItems, setImageTilePathwayItems] = useState<ImageTilePathwayItem[]>();
    const [textFilter, setTextFilter] = useState('');
    const [marketResponse, setMarketResponse] = useState<Responses.IListContentItemsResponse>();
    const [sectorSubsectorList, setSectorSubsectorList] = useState<NestedFilterItem[]>(null);
    const [tradeAgreementCodes, setTradeAgreementCodes] = useState<string[]>([]);
    const [sectorMarkets, setSectorMarkets] = useState({});
    const [regionMarketsData, setRegionMarketsData] = useState([]);
    const [combinedFilter, setCombinedFilter] = useState<string[]>([]);
    const [regionMarketsFilter, setRegionMarketsFilter] = useState([]);

    const pageSize = CommonConstants.PAGE_SIZE;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [currentPageMarkets, setCurrentPageMarkets] = useState<MarketOption[]>(null);

    const fullSectorFlatList = useRef<uniqueContentIdDictionary>({});
    const dispatch = useAppDispatch();

    const sectorTaxonomyGroup = useAppSelector<KenticoSectorTaxonomyGroup>((state) => state.taxonomy.sectorTaxonomyGroup);
    const regionMarketsTaxonomyGroup = useAppSelector<KenticoRegionMarketMapping>((state) => state.taxonomy.regionMarketMapping);
    const tradeAgreementMarkets = useAppSelector<TradeAgreementMarkets>((state) => state.market.marketTradeAgreements);
    const marketSearchData = useAppSelector<CurrentMarketSearchToolSearchData>((state) => state.market.currentMarketSearchResultsInfo);
    const marketImages = useAppSelector<MarketImages>((state) => state.market.marketImages);
    const refResultCountElement = useRef(null);
    const resultsContainerRef = useRef<HTMLDivElement>(null);

    //#region "Fetch Kentico Data and prepare data for the page"
    useEffect(() => {
        dispatch(getKenticoSectorTaxonomyGroup());
        dispatch(getKenticoRegionMarketMapping());
        dispatch(getKenticoMarketTradeAgreements());
        dispatch(getMarketSearchToolSearchData({ Type: MarketSearchToolSearchType.Markets } as MarketSearchRequest));
        dispatch(getKenticoMarketImageData());
    }, []);

    useEffect(() => {
        if (marketSearchData?.results?.length > 0 && marketImages?.data?.length > 0) {
            const marketData = marketSearchData.results as ContentItem[];
            const filteredResponseItems = marketData.filter(
                (mr) => mr.elements.marketcodesnippet__market?.value?.length > 0 && mr.elements.industrysectorcodesnippet__sector?.value?.length > 0,
            );
            const marketProfiles = {
                items: filteredResponseItems,
                pagination: { skip: 0, limit: 0, count: 0, nextPage: '0', totalCount: 0 },
                linkedItems: {},
            };

            setMarketOptionsFromResponse(marketProfiles, marketImages.data);

            setMarketResponse(marketProfiles);

            createSectorMarketsDictionary(marketData);
        }
    }, [marketSearchData, marketImages]);

    useEffect(() => {
        if (marketResponse && sectorTaxonomyGroup.retrieved) {
            let tempIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(marketResponse.items, FilterContentKey.MarketCode);
            fullSectorFlatList.current = tempIndustrySectorFlatList;
            let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(tempIndustrySectorFlatList, sectorTaxonomyGroup.data);
            let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);
            setSectorSubsectorList(tempFilterList);
        }
    }, [sectorTaxonomyGroup.retrieved, marketResponse]);

    useEffect(() => {
        setRegionMarketsData(regionMarketsTaxonomyGroup?.data?.map((item) => ({ ...item, selected: false })));
    }, [regionMarketsTaxonomyGroup.retrieved]);

    useEffect(() => {
        setTradeAgreementCodes(tradeAgreementMarkets?.data);
    }, [tradeAgreementMarkets?.retrieved]);

    useEffect(() => {
        getMarketsByPageNumber(1);
    }, [filteredMarkets]);

    useEffect(() => {
        if (currentPageMarkets) {
            const pathwayItems = currentPageMarkets
                .sort((a, b) => a.Label.localeCompare(b.Label))
                .filter((m) => (!textFilter || m.Label.toLocaleLowerCase().includes(textFilter.toLocaleLowerCase())) && m.ImageUrl)
                .map((m) => {
                    return {
                        systemId: m.Id,
                        title: m.Label,
                        imageUrl: m.ImageUrl,
                        imageAltText: m.AltText,
                        pathway_link: { value: m.LinkUrl },
                    } as ImageTilePathwayItem;
                });

            setImageTilePathwayItems(pathwayItems);
        } else {
            setImageTilePathwayItems([]);
        }
    }, [currentPageMarkets, textFilter]);

    const createSectorMarketsDictionary = (kenticoData: ContentItem[]) => {
        const sectorMarketDictionary = kenticoData
            .filter((item) => item.elements.marketcodesnippet__market?.value?.[0] && item.elements.industrysectorcodesnippet__sector?.value?.[0])
            .reduce((result, item) => {
                const sectors = item.elements.industrysectorcodesnippet__sector.value.map((sector) => sector.codename);
                const marketCode = item.elements.marketcodesnippet__market.value[0].codename;

                for (let sector of sectors) {
                    if (result[sector]) result[sector] = [...result[sector], marketCode].flat();
                    else result[sector] = [marketCode].flat();
                }
                return result;
            }, {});
        setSectorMarkets(sectorMarketDictionary);
    };

    const setMarketOptionsFromResponse = (response: Responses.IListContentItemsResponse, imageData: ContentItem[]) => {
        const markets = extractMarketsFromResponse(response);
        const filteredMarketOptions = extractFilteredMarketOptions(imageData, markets);

        setFilteredMarkets(filteredMarketOptions);

        const marketCount = filteredMarketOptions?.length || 0;
        setPaginationParameters(marketCount);
        setCurrentPage(1);
    };

    const extractMarketsFromResponse = (response: Responses.IListContentItemsResponse) => {
        const markets = Array.from(
            response.items
                .filter((item) => item.elements.marketcodesnippet__market?.value)
                .map((item) => item.elements.marketcodesnippet__market.value),
        ).flat();

        const uniqueMarkets = markets.filter((item, index, array) => array.findIndex((i) => i.codename === item.codename) === index);
        return uniqueMarkets;
    };

    const extractFilteredMarketOptions = (images: ContentItem[], markets: Array<{ codename: string; name: string }>) => {
        return markets
            .sort((next, prev) => next.name.localeCompare(prev.name))
            .map((market: any) => {
                const marketCode = market.codename;
                const marketImageItem = getImageItemByMarketCode(images, marketCode);
                return {
                    Id: getImageItemSystemId(marketImageItem),
                    Code: marketCode.toLowerCase(),
                    Label: market.name.split('|')[0].trim(),
                    ImageUrl: getImageItemUrl(marketImageItem),
                    AltText: getImageItemDescription(marketImageItem),
                    LinkUrl: `/${PageName.FindExportMarkets}/${PageName.MarketExplorerTool}/${PageName.MarketExplorerResults}/all/all/${marketCode.toLowerCase()}`,
                } as MarketOption;
            });
    };
    //#endregion

    //#region "Filter Methods"
    const getMarketsFilteredByText = (marketList: ContentItem[], marketFilterText: string) => {
        let filteredMarketList = [...marketList];
        let filteredMarketCodes = [];
        if (marketFilterText) {
            filteredMarketList = filteredMarketList.filter((item) =>
                getMarketDisplayName(item.elements.marketcodesnippet__market?.value?.[0]?.name)
                    .toLocaleLowerCase()
                    .includes(marketFilterText.toLocaleLowerCase()),
            );
            filteredMarketCodes = filteredMarketList?.map((fm) => fm.elements.marketcodesnippet__market?.value?.[0]?.codename);
        }

        return { filteredMarketList, filteredMarketCodes };
    };

    const getMarketListAndCountForRegion = (filter, markets: any[], code: string) => {
        let allFilters = Object.values(sectorMarkets).flat();
        if (filter.length > 0) allFilters = intersection(allFilters.flat(), filter.flat());

        const filteredMarkets =
            allFilters.length > 0
                ? markets.filter((market) => allFilters.includes(market.code)).map((m) => `${code}:${m.code}:${code}`)
                : markets.map((m) => `${code}:${m.code}:${code}`);

        return { markets: filteredMarkets, count: filteredMarkets?.length || 0 };
    };

    const getRegionList = useCallback(
        (filter) =>
            regionMarketsData?.map((item) => ({
                value: item.code,
                label: item.description,
                ...getMarketListAndCountForRegion(filter, item.markets, item.code),
            })),
        [regionMarketsData, getMarketListAndCountForRegion],
    );

    const getSectorFilter = useCallback(
        (industrySectorCriteriaList: stringFilterDictionary) => {
            let filteredSectorMarkets = [];
            const sectorsSelected = Object.entries(industrySectorCriteriaList)
                .filter(([_, value]) => value)
                .map(([key, _]) => (key.split(':').length > 0 ? key.split(':')[key.split(':').length - 1] : key));

            const industrySectorFlatList = {};
            sectorSubsectorList?.forEach((sector) => {
                if (sectorsSelected.includes(sector.value)) industrySectorFlatList[sector.value] = sector.count;
                sector.subFilterItems?.forEach((subSector) => {
                    if (sectorsSelected.includes(subSector.value)) industrySectorFlatList[subSector.value] = subSector.count;
                });
            });

            const nonEmptySectorExists = Object.values(industrySectorFlatList).some((value) => (value as number) > 0);

            filteredSectorMarkets = Object.entries(sectorMarkets)
                .map(([sectorCode, countries]) => (sectorsSelected.includes(sectorCode) ? countries : undefined))
                .filter(Boolean)
                .flat();
            filteredSectorMarkets = nonEmptySectorExists ? Array.from(new Set(filteredSectorMarkets)) : [];

            return filteredSectorMarkets;
        },
        [sectorSubsectorList],
    );

    const onFilterChange = useCallback(
        (filterCriteria: MarketsFilterCriteria) => {
            const { industrySectorCriteriaList, regionCriteriaList, filterByTradeAgreements, marketFilter: marketFilterText } = filterCriteria;
            setTextFilter(marketFilterText);

            let tempFilteredList = marketResponse?.items;
            let { filteredMarketList, filteredMarketCodes } = getMarketsFilteredByText(marketResponse?.items, marketFilterText);
            tempFilteredList = filteredMarketList;

            const filteredSectorMarkets = getSectorFilter(industrySectorCriteriaList);

            const regionsFilter = [];
            if (filteredSectorMarkets.length > 0) regionsFilter.push(filteredSectorMarkets);
            if (filterByTradeAgreements) regionsFilter.push(tradeAgreementCodes);
            if (filteredMarketCodes.length > 0) regionsFilter.push(filteredMarketCodes);
            setRegionMarketsFilter(intersection(...regionsFilter));

            const regionListForFilter = getRegionList(regionsFilter);
            const refinedRegionCriteriaList = regionListForFilter?.reduce((result, r) => {
                if (regionCriteriaList[r.value]) {
                    result[r.value] = true;
                    r.markets.forEach((m) => (result[m] = true));
                }
                return result;
            }, {});

            const tempRegionMarketsFilter = regionMarketsData
                ?.map((rm) => (regionCriteriaList[rm.code] && regionListForFilter.find((r) => r.value === rm.code)?.count > 0 ? rm.markets : []))
                .flat()
                ?.map((market) => market.code);

            const combineFilters = [];
            if (filteredSectorMarkets.length > 0) combineFilters.push(filteredSectorMarkets);
            if (tempRegionMarketsFilter.length > 0) combineFilters.push(tempRegionMarketsFilter);
            if (filterByTradeAgreements) regionsFilter.push(tradeAgreementCodes);
            if (filteredMarketCodes.length > 0) combineFilters.push(filteredMarketCodes);
            setCombinedFilter(intersection(...combineFilters));

            tempFilteredList = filterUtils.applySectorFilterToContentItemsList(industrySectorCriteriaList, tempFilteredList);
            tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(refinedRegionCriteriaList, tempFilteredList);
            if (filterByTradeAgreements) {
                tempFilteredList = tempFilteredList.filter((item) =>
                    tradeAgreementCodes.includes(item.elements.marketcodesnippet__market?.value?.[0]?.codename),
                );
            }
            setMarketOptionsFromResponse({ ...marketResponse, items: tempFilteredList }, marketImages.data);

            updateIndustrySectorFilterPanel(filterCriteria, refinedRegionCriteriaList);
        },
        [sectorMarkets, tradeAgreementCodes, marketResponse, marketImages, getSectorFilter],
    );

    const updateIndustrySectorFilterPanel = (filterCriteria: MarketsFilterCriteria, refinedRegionCriteriaList: any) => {
        const { filterByTradeAgreements, marketFilter: marketFilterText } = filterCriteria;
        let tempFilteredList = [...marketResponse.items] as ContentItem[];

        const { filteredMarketList } = getMarketsFilteredByText(tempFilteredList, marketFilterText);
        tempFilteredList = filteredMarketList;

        if (checkFilterSelectionCount(refinedRegionCriteriaList) > 0) {
            tempFilteredList = filterUtils.applyRegionMarketFilterToContentItemsList(refinedRegionCriteriaList, [...tempFilteredList]);
        }

        if (filterByTradeAgreements)
            tempFilteredList = tempFilteredList.filter((item) =>
                tradeAgreementCodes.includes(item.elements.marketcodesnippet__market?.value?.[0]?.codename),
            );

        updateIndustrySectorFilterPanelListCount([...tempFilteredList]);
    };

    const checkFilterSelectionCount = (criteria: stringFilterDictionary) => {
        let count = 0;
        if (!criteria || Object.keys(criteria).length === 0) return 0;
        Object.keys(criteria).forEach((item: string) => {
            if (criteria[item]) {
                count++;
            }
        });
        return count;
    };

    const updateIndustrySectorFilterPanelListCount = (filteredMarketsList: ContentItem[]) => {
        let filteredIndustrySectorFlatList = filterUtils.getIndustrySectorFlatListFromContentItems(filteredMarketsList, FilterContentKey.MarketCode);
        let updatedIndustrySectorFlatList = filterUtils.updateIndustrySectorFlatList(filteredIndustrySectorFlatList, fullSectorFlatList.current);
        let tempIndustrySectorDictionaryList = filterUtils.getIndustrySectorDictionaryList(updatedIndustrySectorFlatList, sectorTaxonomyGroup.data);
        let tempFilterList = filterUtils.getIndustrySectorFilterPanelList(tempIndustrySectorDictionaryList, sectorTaxonomyGroup.data);

        setSectorSubsectorList(tempFilterList);
    };
    //#endregion

    //#region "Pagination Methods"
    const getMarketsByPageNumber = (pageNumber: number) => {
        if (!filteredMarkets) return;

        let offset = (pageNumber - 1) * pageSize;
        let pagedItems = drop(filteredMarkets, offset).slice(0, pageSize);
        setCurrentPageMarkets(pagedItems);
        setCurrentPage(pageNumber);
    };

    const scrollToPageTop = () => {
        scrollTo(refResultCountElement);
        setTimeout(() => resultsContainerRef?.current?.focus(), 50);
    };

    const setPaginationParameters = (resultsCount: number) => {
        setTotalPages(Math.ceil(resultsCount / pageSize));
    };

    const onFirstPageClick = () => {
        getMarketsByPageNumber(1);
        scrollToPageTop();
    };

    const onPreviousPageClick = () => {
        if (currentPage > 1) {
            getMarketsByPageNumber(currentPage - 1);
        }
        scrollToPageTop();
    };

    const onNextPageClick = () => {
        if (currentPage < totalPages) {
            getMarketsByPageNumber(currentPage + 1);
        }
        scrollToPageTop();
    };

    const onLastPageClick = () => {
        getMarketsByPageNumber(totalPages);
        scrollToPageTop();
    };

    function onPageNumberClick(pageNumber: number) {
        getMarketsByPageNumber(pageNumber);
        scrollToPageTop();
    }
    //#endregion

    return (
        <LoadingOverlay active={!filteredMarkets} spinner={<SyncLoader />} text="Please wait" className="loader">
            <Row>
                <Col>
                    {filteredMarkets?.length > 0 ? (
                        <h2 className="results-count-header" ref={refResultCountElement}>
                            Showing <strong>{filteredMarkets?.length}</strong> {filteredMarkets?.length === 1 ? 'market' : 'markets'}
                        </h2>
                    ) : (
                        <h2 className="results-count-header">No markets to show</h2>
                    )}
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    {sectorSubsectorList?.length > 0 && regionMarketsTaxonomyGroup?.data?.length > 0 && (
                        <FilterMarketsPanel
                            data={{
                                sectorSubsectorList: sectorSubsectorList,
                                regionList: getRegionList(regionMarketsFilter),
                                tradeAgreementCount:
                                    combinedFilter.length > 0
                                        ? tradeAgreementCodes?.filter((tac) => combinedFilter.includes(tac)).length
                                        : tradeAgreementCodes?.length,
                            }}
                            onChange={onFilterChange}
                        />
                    )}
                </Col>
                <Col md={8}>
                    <div className="market-explorer-box-container" ref={resultsContainerRef} tabIndex={-1}>
                        <ImageTilePathway data={{ items: imageTilePathwayItems }} client={client} pageLayoutType={PageLayoutType.TwoColumnPage} />
                    </div>
                    {imageTilePathwayItems?.length > 0 && (
                        <div className="pagination-links-container" data-testid={'pagination'}>
                            {totalPages > 0 && (
                                <PaginationLinks
                                    data={{
                                        totalPages: totalPages,
                                        currentPage: currentPage,
                                        pageSize: pageSize,
                                        onPageNumberClick: onPageNumberClick,
                                        onFirstPageClick: onFirstPageClick,
                                        onLastPageClick: onLastPageClick,
                                        onNextPageClick: onNextPageClick,
                                        onPreviousPageClick: onPreviousPageClick,
                                    }}
                                />
                            )}
                        </div>
                    )}
                </Col>
            </Row>
        </LoadingOverlay>
    );
};

export default MarketExplorerSearchContainer;
