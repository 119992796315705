import React, { BaseSyntheticEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ComFieldInput } from '@exporter-services/common-ui';
import { useLazyGetAbnDetailsQuery } from '../../providers/reducers/userProfileApi';
import './AbnField.scss';

interface AbnFieldProps {
    required?: boolean;
    isFetchingAbnDetails?: Dispatch<SetStateAction<boolean>>;
}

export const AbnField = ({ required = false, isFetchingAbnDetails }: AbnFieldProps) => {
    const [abnInvalid, setAbnInvalid] = useState(false);
    const [businessName, setBusinessName] = useState('');
    const [getAbnDetails] = useLazyGetAbnDetailsQuery();
    const formContext = useFormContext();
    const {
        register,
        trigger,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = formContext || {};

    const requiredErrMsg = 'ABN is mandatory. Make sure it has 11 digits and is active in the Australian Business Register.';
    const invalidErrMsg = "This ABN isn't valid. Make sure it has 11 digits and is active in the Australian Business Register.";

    const validateAbn = useCallback(
        (value: string) => {
            if (!value && required) return requiredErrMsg;
            if (value && abnInvalid) return invalidErrMsg;
        },
        [abnInvalid],
    );

    const formatAbn = (abn: string) => {
        const strippedAbn = abn?.replaceAll(' ', '');
        setValue(
            'abn',
            strippedAbn
                .match(/^(\d{2})(\d{3})(\d{3})(\d{3})$/)
                ?.slice(1)
                .join(' '),
        );
        return strippedAbn;
    };

    const processAbnDetails = (abnDetails) => {
        if (abnDetails.isSuccess && abnDetails.data.IsActive) {
            setBusinessName(abnDetails.data.BusinessName);
            setAbnInvalid(false);
        } else {
            setBusinessName('');
            setAbnInvalid(true);
            setError('abn', { message: invalidErrMsg });
        }
    };

    const triggerAbnValidation = async (abn: string) => {
        const result = await trigger('abn');
        if (result) {
            try {
                isFetchingAbnDetails?.(true);
                const abnDetails = await getAbnDetails({ abn: formatAbn(abn) });
                processAbnDetails(abnDetails);
            } finally {
                isFetchingAbnDetails?.(false);
            }
        }
    };

    const handleBlur = (event: BaseSyntheticEvent) => {
        setAbnInvalid(false);
        if (event?.target?.value) {
            setTimeout(async () => {
                await triggerAbnValidation(event.target.value);
            }, 10);
        }
        if (!event?.target?.value) setBusinessName('');
    };

    const handleKeyDown = (event: BaseSyntheticEvent | KeyboardEvent) => {
        const keyPressed = (event as KeyboardEvent).key;
        if (keyPressed === 'Enter') {
            // Trigger blur to fire validation, and then reset focus
            event.target.blur();
            setTimeout(async () => {
                event.target.focus();
            }, 10);
        }
    };

    useEffect(() => {
        if (getValues('abn')) triggerAbnValidation(getValues('abn'));
    }, []);

    return (
        <>
            <ComFieldInput
                controlId="abn"
                label="Australian Business Number (ABN)"
                hintText="Your ABN should have 11 digits and be active in the <a href='https://abr.business.gov.au/' target='_blank'>Australian Business Register</a>."
                errorMessage={errors?.abn?.message as string}
                required={required}
                onKeyDown={handleKeyDown}
                {...register?.('abn', {
                    pattern: {
                        value: /^(\d\s*?){11}$/,
                        message: invalidErrMsg,
                    },
                    validate: {
                        required: validateAbn,
                    },
                    onBlur: handleBlur,
                })}
            />
            {businessName && !errors?.abn?.message && (
                <p className="text-wrap text-break margin-top-n3">
                    <span className="business-name-label">Name:&nbsp;</span>
                    {businessName}
                </p>
            )}
        </>
    );
};
