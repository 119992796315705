import React from 'react';
import ComponentResolver from '../../ComponentResolver';
import { Col, Row } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import './SectorLandingPage.scss';

interface SectorLandingPageProps {
    data: {
        itemNames: Array<string>;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

const SectorLandingPage = (props: SectorLandingPageProps) => {
    const {
        data: { itemNames, linkedItems },
        match,
    } = props;

    const renderComponent = (isCrmForm = false) => {
        const filteredItems = isCrmForm
            ? itemNames?.filter((item) => linkedItems?.[item]?.system?.type === 'crm_form')
            : itemNames?.filter((item) => linkedItems?.[item]?.system?.type !== 'crm_form');

        return filteredItems?.map((item) => <ComponentResolver data={{ item: linkedItems?.[item], linkedItems }} key={item} match={match} />);
    };

    return (
        <Row>
            <Col md={7}>{renderComponent()}</Col>
            <Col md={5} className="d-print-none">
                <div className="crmFormContainer sticky-top">{renderComponent(true)}</div>
            </Col>
        </Row>
    );
};

export default SectorLandingPage;
